import { useQuery } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { PROJECT } from 'src/lib/queries';

export function useProject(args: object) {
  const [accessToken] = useLocalStorage('accessToken', undefined);

  const options = accessToken
    ? {
        context: {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        },
      }
    : {};

  return useQuery(PROJECT, { ...options, ...args });
}
