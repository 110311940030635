// src/components/Modal.tsx
'use client';

import { Dialog, Transition } from '@headlessui/react';
import { variantProps, VariantPropsOf } from 'classname-variants/react';
import classNames from 'classnames';
import React, { ElementType, Fragment } from 'react';
import { Close } from 'src/assets/icons';
import '../assets/css/global.css';
import IconButton from './ui/Button/IconButton';
import HorizontalDivider from './ui/HorizontalDivider';

const modalProps = variantProps({
  // base: 'sm:w-full relative text-left transform rounded-xl bg-[var(--backgrounds-blanket)]/50 border border-[var(--border-on-bg)] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)] bg-white dark:bg-[#212121] transition-all sm:my-8',
  variants: {
    size: {
      small: 'sm:max-w-lg',
      medium: 'sm:max-w-xl',
      large: 'sm:max-w-2xl',
    },
  },
  defaultVariants: {
    size: 'medium',
  },
});

export default function Modal(
  props: {
    as?: ElementType;
    bodyClassName?: string;
    children: React.ReactNode;
    footer?: React.ReactNode;
    handleClose: Function;
    show: boolean;
    title?: React.ReactNode;
  } & VariantPropsOf<typeof modalProps>
) {
  const {
    children,
    footer,
    title,
    as = 'div',
    show,
    handleClose,
    bodyClassName,
  } = props;

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as={as} className="relative z-10" onClose={() => handleClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto  bg-[var(--backgrounds-dropdowns)]">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel {...modalProps(props)}>
                {/* <div> */}
                {title && (
                  <>
                    <div className="p-5 flex items-center justify-between w-full">
                      {/* <button type="button" onClick={() => handleClose()} className="absolute left-4 focus:outline-none">
                      <XCloseDelete size={20} />
                    </button> */}
                      <div className="text-body-large medium text-[var(--text-default)]">
                        {title}
                      </div>
                      <IconButton type="ghost" size="micro" icon={<Close />} />
                    </div>
                    <HorizontalDivider />
                  </>
                )}
                <div className={classNames('p-6 space-y-12', bodyClassName)}>
                  {children}
                </div>
                {footer && <div className="p-4">{footer}</div>}
                {/* </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
