import { useQuery } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { INBOX } from 'src/lib/queries';

export function useInbox(args?: object) {
  const [accessToken] = useLocalStorage('accessToken', undefined);

  const options = accessToken
    ? {
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        fetchPolicy: 'network-only' as const,
      }
    : {};

  return useQuery(INBOX, { ...options, ...args });
}
