import React from 'react';
// import Button from "src/components/Button";
import toast from 'react-hot-toast';
import { Close } from 'src/assets/icons';
import Button from './ui/Button/Button';

function ToastMessage({
  title,
  buttonText,
  handleUndo,
  icon,
  children,
  id,
}: {
  id: any;
  children?: React.ReactNode;
  handleUndo?: Function;
  icon?: React.ReactNode;
  title: string;
  buttonText?: string;
}) {
  const handleDismiss = () => toast.dismiss(id);

  return (
    <div className="flex gap-4 items-center justify-between w-full text-body-small medium !text-[var(--text-on-bg)]">
      {icon && <div className="py-1">{icon}</div>}
      <div className="w-full flex items-start flex-col gap-y-1">
        {title && <h2 className="!text-[var(--Text-on-bg)]">{title}</h2>}
        {children && <div className="mt-1">{children}</div>}
        {handleUndo && buttonText && (
          <Button
            onClick={() => handleUndo()}
            type="button"
            size="small"
            className="w-fit"
            value={buttonText}
            btnType={'neutral'}
          />
        )}
      </div>
      <button onClick={handleDismiss} className="w-fit">
        <div>
          <div>
            <Close />
          </div>
        </div>
      </button>
    </div>
  );
}

export default ToastMessage;
