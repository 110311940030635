import React from 'react';

interface SlotProps {
  Col1: React.ReactNode;
  Col2: React.ReactNode;
  menu: React.ReactNode;
  onClick?: () => void;
  containerClassName?: string;
}

const TableLayout: React.FC<SlotProps> = ({
  Col1,
  Col2,
  menu,
  onClick,
  containerClassName,
}) => {
  return (
    <div
      className={`table_layout grid gap-0 items-center h-16 w-full ${containerClassName}`}
    >
      {Col1}
      {Col2}
      {menu}
      {/*<div className='relative'>*/}
      {/*    <IconButton className='ml-auto' icon={<ThreeDots />} size='micro' type='ghost' onClick={onClick} />*/}
      {/*</div>*/}
    </div>
  );
};

export default TableLayout;
