import { Settings } from 'src/assets/icons';

export default function ComingSoon() {
  return (
    <div className="absolute pointer-events-none top-0 left-0 h-[calc(100svh-42px)] w-full flex items-center justify-center flex-col z-[1] gap-5">
      <div className="flex flex-col items-center gap-1">
        <Settings className="w-8 h-8 animate-spin" />
        <h6 className="text-heading-6 medium text-[var(--text-default)]">
          Coming soon
        </h6>
        <p className="text-body-small regular text-[var(--text-neutral)]">
          This page is under construction.
        </p>
      </div>
    </div>
  );
}
