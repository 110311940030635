import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Plus } from 'src/assets/icons';
import SideNavMenuBtn from 'src/components/sideNav/SideNavMenu';
import IconButton from 'src/components/ui/Button/IconButton';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import MobileWorkSpaceMenu from 'src/components/ui/MobileWorkSpaceMenu';
import SectionWrap from 'src/components/ui/SectionWrap';
import { useProjectContext } from 'src/context/ProjectContext';
import useMobile from 'src/hooks/useMobile';
import { colorStyles } from 'src/lib/colorPreset';
import { getColorTag } from 'src/lib/helpers';
import { useMe } from '../api/auth/useMe';
import EditProjectModal from '../components/EditProjectModal';
import { IProject, IUserWorkspace, IWorkspace } from '../lib/types';

const getCurrentTheme = (
  theme: 'light' | 'dark' | 'system'
): 'light' | 'dark' => {
  if (theme === 'system') {
    const prefersDarkScheme = window.matchMedia(
      '(prefers-color-scheme: dark)'
    ).matches;
    return prefersDarkScheme ? 'dark' : 'light';
  }
  return theme;
};

function MobileHome() {
  const isMobile = useMobile();
  const { projects } = useProjectContext();

  const navigate = useNavigate();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [createProject, setCreateProject] = useState(false);
  const { loading, data } = useMe();
  const userWorkspaces = data?.me?.userWorkspaces;
  const userWorkspace = userWorkspaces?.find(
    ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
  );

  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

  useEffect(() => {
    if (projects.length > 0) {
      navigate(
        `/workspace/${workspaceSlug}/project/${
          isMobile ? 'all' : projects[0].id
        }`
      );
    } else {
      navigate(`/workspace/${workspaceSlug}/project/`);
    }
  }, [projects, workspaceSlug, navigate]);

  useEffect(() => {
    const workspaces = data?.me?.userWorkspaces.map(
      ({ workspace }: IUserWorkspace) => workspace
    );
    const workspace = workspaces?.find(
      (each: IWorkspace) => each.slug === workspaceSlug
    );
  }, [data, workspaceSlug]);

  if (loading) return <PageLoader snippet="Loading user projects" />;

  return (
    <>
      <SectionWrap className="">
        <div className="page-title z-1 flex items-center justify-between md:hidden">
          <MobileWorkSpaceMenu
            userWorkspace={userWorkspace}
            userWorkspaces={userWorkspaces}
            user={data.me}
          />
          <p className="tet-body-small bold text-[var(--text-neutral)]">
            Projects
          </p>
          <IconButton
            icon={<Plus />}
            size={'small'}
            type={'ghost'}
            onClick={() => setCreateProject(true)}
          />
        </div>
        <div className="flex flex-col gap-2 w-full mt-20 pt-1.5 px-4">
          {projects.map((project: IProject, key) => (
            <Link key={project.id} to={`${WORKSPACE_URL_BASE}/${project.id}`}>
              <SideNavMenuBtn
                avatarColor={colorStyles[getColorTag(project.name)]}
                sideAvatar={project.name}
                type="home"
                label={project.name}
                link={`${WORKSPACE_URL_BASE}/${project.id}`}
                showBadge={false}
              />
            </Link>
          ))}
          {/* <Button value={'New project'} size={'medium'} btnType={'ghost'} icon='left' className='justify-end' onClick={secNavFunc}>
                    <Plus />
                </Button> */}
        </div>
      </SectionWrap>

      <EditProjectModal
        show={createProject}
        handleClose={() => setCreateProject(false)}
      />
    </>
  );
}

export default MobileHome;
