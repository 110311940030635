import { useQuery, useMutation } from '@apollo/client';
import { ME } from 'src/lib/queries';
import { useLocalStorage } from '@uidotdev/usehooks';
import { SEND_AUTH_TOKEN } from 'src/lib/mutations';

export function useMe() {
  const [accessToken] = useLocalStorage('accessToken', undefined);

  const options = accessToken
    ? {
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
        fetchPolicy: 'network-only',
      }
    : {};

  return useQuery(ME, options as any);
}

export function useSendAuthToken() {
  return useMutation(SEND_AUTH_TOKEN);
}
