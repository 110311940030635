import React from 'react';

interface IToggleProps {
  id: string;
  isChecked: boolean;
  disabled?: boolean;
  onChange: (checked: boolean) => void;
  label?: string;
  className?: string;
}

const SwitchToggle: React.FC<IToggleProps> = ({
  id,
  disabled,
  isChecked,
  onChange,
  label = 'Toggle',
  className = '',
}) => {
  return (
    <div className={className}>
      <input
        className="toggleswitch flex items-center"
        type="checkbox"
        id={id}
        checked={isChecked}
        disabled={disabled}
        onChange={() => onChange(!isChecked)}
      />
      {label && (
        <label className="toggleLabel" htmlFor={id}>
          {label}
        </label>
      )}
    </div>
  );
};

export default SwitchToggle;
