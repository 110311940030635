import { Toaster } from 'react-hot-toast';
// import {ApolloWrapper} from "./components/ApolloWrapper";
import { ApolloProvider } from '@apollo/client';
import 'non.geist';
import { BrowserRouter } from 'react-router-dom';
import { useApolloClient } from 'src/hooks/useApolloClient';
import PageRoutes from './pages/PageRoutes';

import './App.css';
import './assets/css/global.css';
import { ToastProvider } from './context/ToastContext';
// import { Toaster } from "sonner";
import { ModalProvider } from './context/ModalContext';
import { ThemeProvider } from './context/ThemeContext';

function App() {
  const client = useApolloClient();
  return (
    <ThemeProvider>
      <ApolloProvider client={client}>
        <ToastProvider>
          <ModalProvider>
            <Toaster
              position="bottom-right"
              containerClassName={'mb-6'}
              toastOptions={{
                className:
                  'flex max-w-[406px] w-full p-[8px] px-[6px] items-center gap-[16px] rounded-[8px] border border-[var(--border-default)] bg-[var(--backgrounds-dropdowns)] shadow-[0px_3px_2px_0px_rgba(7,7,7,0.05)]',
                duration: 5000,
              }}
            />
            {/* <Toaster /> */}
            <BrowserRouter>
              <PageRoutes />
            </BrowserRouter>
          </ModalProvider>
        </ToastProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
