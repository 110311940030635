import React, {useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {useLocalStorage} from "@uidotdev/usehooks";
import toast from "react-hot-toast";
import ToastMessage from "../../components/ToastMessage";

function AuthCallbackPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');
  const userId = searchParams.get('userId');
  const error = searchParams.get('error');
  const [_, setUserId] = useLocalStorage<string>("userId", undefined);
  const [__, setAccessToken] = useLocalStorage<string>("accessToken", undefined);
  const [authError, setAuthError] = useState<string>();

  useEffect(() => {
    if (accessToken) {
      setAccessToken(accessToken);
    }

    if (userId) {
      setUserId(userId)
    }

    if (error) {
      setAuthError(error);
    }
  }, [accessToken, error, setAccessToken, setUserId, userId]);

  useEffect(() => {
    if (authError) {
      navigate('/', {replace: true});
      toast(t => <ToastMessage id={t.id} title={authError}/>);
    } 
    navigate('/', {replace: true});
  }, [authError, navigate]);

  return null;
}

export default AuthCallbackPage;
