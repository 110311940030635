import React from 'react';
import { colorStyles } from 'src/lib/colorPreset';
import { getColorTag } from 'src/lib/helpers';
import '../ui.css';

interface DefaultAvatarProps {
  name: string;
  size: number;
  fontSize: number;
  fontWeight: number;
}

const DefaultAvatar: React.FC<DefaultAvatarProps> = ({
  name,
  size,
  fontSize,
  fontWeight,
}) => {
  const initial = name
    .split(' ')
    .map((name) => name.charAt(0).toUpperCase())
    .join('')
    .substring(0, 1);

  return (
    <div>
      <div
        className="avatar_wrap logo_avatar flex items-center justify-center aspect-square cursor-pointer"
        style={
          {
            '--size': `${size}px`,
            fontSize: `${fontSize}px`,
            fontWeight: fontWeight,
            ...colorStyles[getColorTag(name)],
          } as unknown as React.CSSProperties
        }
      >
        <div>{initial}</div>
      </div>
    </div>
  );
};

export default DefaultAvatar;
