import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import usePrevious from "../../hooks/usePrevious";
import { useMutation } from "@apollo/client";
import { CHECK_AUTH_TOKEN } from "../../lib/mutations";
import { useSendAuthToken } from "../../api/auth/useSendAuthToken";
import { useLocalStorage } from "@uidotdev/usehooks";
import OtpInput from 'react-otp-input';
import Button from 'src/components/ui/Button/Button';
import BackButton from 'src/components/ui/Button/BackButton';

function VerifyOtpPage() {
  const numInputs = 4;
  const resendCooldown = 60; // Resend cooldown in seconds
  const [searchParams] = useSearchParams();
  const encodedEmail = searchParams.get('email');
  const email = encodedEmail ? decodeURIComponent(encodedEmail) : '';

  const ui = searchParams.get('ui');
  const [token, setToken] = useState('');
  const [resendTimer, setResendTimer] = useState(resendCooldown);
  const previousToken = usePrevious(token);
  const isValidToken = (token: string) => token.length === numInputs;
  const isBasicUI = ui === 'basic';

  const [checkAuthToken, { loading, error }] = useMutation(CHECK_AUTH_TOKEN);
  const [sendAuthToken, { loading: resendLoading, error: resendError }] = useSendAuthToken();
  const errorMessage = error && `Submission error! ${error.message}`;

  const [accessToken, setAccessToken] = useLocalStorage("accessToken", undefined);
  const [userId, setUserId] = useLocalStorage("userId", undefined);

  const handleSubmit = useCallback((e?: FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    if (!isValidToken(token)) return;

    checkAuthToken({
      variables: {
        data: { email, token },
      },
      onCompleted: ({ checkAuthToken }) => {
        if (checkAuthToken.userId && checkAuthToken.accessToken) {
          setUserId(checkAuthToken.userId);
          setAccessToken(checkAuthToken.accessToken);
        }
      }
    });
  }, [checkAuthToken, email, token, setAccessToken, setUserId]);

  const handleResend = async () => {
    try {
      sendAuthToken({
        variables: { email },
        onCompleted: data => {
          // Reset the resend timer
          setResendTimer(resendCooldown);
        }
      });
    } catch (resendError) {
      console.error('Resend OTP error:', resendError);
    }
  };

  useEffect(() => {
    // Countdown logic for resend timer
    const countdownInterval = setInterval(() => {
      if (resendTimer > 0) {
        setResendTimer(prevTimer => prevTimer - 1);
      }
    }, 1000);

    // Clear the interval when component unmounts
    return () => clearInterval(countdownInterval);
  }, [resendTimer]);

  useEffect(() => {
    if (previousToken !== token && isValidToken(token)) {
      handleSubmit();
    }
  }, [handleSubmit, previousToken, token]);

  return (
    <div className='flex flex-col gap-10 md:gap-14'>
      <div className="w-full flex flex-col gap-4 items-start">
        <BackButton />
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">Verify OTP</h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            We just sent a {numInputs}-digit OTP to <span className='medium text-[var(--text-default)]'>{email}</span>, kindly enter the numbers and continue
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4 items-start">
        <form
          onSubmit={handleSubmit}
          className='w-full flex flex-col gap-6'
        >
          <div>
            <OtpInput
              value={token}
              onChange={setToken}
              numInputs={numInputs}
              renderInput={(props) => <input {...props} />}
              containerStyle="space-x-5"
              inputStyle="otp-input input w-full flex flex-col items-center justify-center h-16 p-4 rounded rounded-lg outline outline-[var(--border-input-default)] bg-[var(--backgrounds-on-canvas)]"
              // disabled={loading}
              shouldAutoFocus
            />
            {errorMessage && (
              <p className="text-xs text-red-500 text-center">
                {errorMessage}
              </p>
            )}
          </div>
          <Button
            type="submit"
            btnType="primary"
            size="large"
            value='Verify'
            loading={loading}
            disabled={loading}
            className="w-full"
          />
        </form>
        <Button
          btnType="ghost"
          size="medium"
          value={resendTimer > 0 ? `Resend ${resendTimer}s` : "Didn’t get the code? Resend"}
          loading={loading}
          disabled={loading}
          className="w-full"
          onClick={handleResend}
        />
      </div>
    </div>
  );
}

export default VerifyOtpPage;
