import { useLocalStorage } from '@uidotdev/usehooks';
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { usePosts } from "src/api/post/usePosts";
import PageLoader from 'src/components/ui/Loader/PageLoader';
import { IPost, IProjectLink, ProjectStatus } from "src/lib/types";

interface PostsContextProps {
    projectId: string | undefined;
    workspaceSlug: string | undefined;
    posts: IPost[] | undefined;
    setPosts: React.Dispatch<React.SetStateAction<IPost[] | undefined>>;
    projectDetails: IProjectDetails | undefined;
    setProjectDetails: React.Dispatch<React.SetStateAction<IProjectDetails | undefined>>;
}

const PostsContext = createContext<PostsContextProps | undefined>(undefined);

export function usePostsContext() {
    const context = useContext(PostsContext);

    if (!context) {
        throw new Error('usePostsContext must be used within a PostsContextProvider');
    }
    return context;
}

interface PostsContextProviderProps {
    children: ReactNode;
}

type IProjectDetails = {
    name: string;
    description: string;
    collaboratorIDs: string[];
    topicIDs: string[];
    goalIDs: string[];
    status: ProjectStatus;
    isPublic: boolean;
    links: IProjectLink[];
}

export function PostsContextProvider({ children }: PostsContextProviderProps) {
    const { projectId, workspaceSlug } = useParams<{ projectId: string, workspaceSlug: string }>();
    const [userId] = useLocalStorage("userId", undefined);

    // State definitions
    const [posts, setPosts] = useState<IPost[] | undefined>();
    const [projectDetails, setProjectDetails] = useState<IProjectDetails | undefined>({
        name: "",
        description: "",
        collaboratorIDs: [],
        topicIDs: [],
        goalIDs: [],
        status: ProjectStatus.PLANNING,
        isPublic: true,
        links: [],
    })

    // GraphQL definitions
    const { loading, data, refetch } = usePosts({
        variables: {
            where: {
                projectId: { equals: projectId }
            },
            orderBy: { createdAt: 'desc' }
        }
    });

    // Effect to update posts when data changes
    useEffect(() => {
        if (data) {
            setPosts(data.posts);
        }
    }, [data]);

    // Effect to refetch posts when projectId changes
    useEffect(() => {
        if (projectId) {
            refetch({
                where: {
                    projectId: { equals: projectId }
                },
                orderBy: { createdAt: 'desc' }
            });
        }
    }, [projectId, refetch]);

    if (loading) return <PageLoader snippet="Loading your workspace's projects, and posts" />;

    return (
        <PostsContext.Provider value={{ posts, setPosts, projectDetails, setProjectDetails, projectId, workspaceSlug }}>
            {children}
        </PostsContext.Provider>
    );
}
