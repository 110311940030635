import { useLocalStorage } from '@uidotdev/usehooks';
import { Fragment, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useMe } from '../api/auth/useMe';
import { useProject } from '../api/project/useProject';
import { useUpdateOneProject } from '../api/project/useUpdateOneProject';
import EditProjectHeading from '../components/EditProjectHeading';
import ToastMessage from '../components/ToastMessage';
import { deleteTypeNameKey, getColorTag } from '../lib/helpers';
import { IProjectLink, ProjectStatus } from '../lib/types';
// import EditProjectDetails2 from "../components/EditProjectDetails2";
// import Avatar from "../components/Avatar";
import clsx from 'clsx';
import TextareaAutosize from 'react-textarea-autosize';
import { useArchiveOneProject } from 'src/api/project/useArchiveOneProject';
import { useDeleteOneProject } from 'src/api/project/useDeleteOneProject';
import { ArrowBack, ArrowUp, Menu } from 'src/assets/icons';
import ArchiveProject from 'src/components/ArchiveProject';
import EditProjectDetails2 from 'src/components/EditProjectDetails2';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import SectionWrap from 'src/components/ui/SectionWrap';
import { useProjectContext } from 'src/context/ProjectContext';
import useMobile from 'src/hooks/useMobile';
import { colorStyles } from 'src/lib/colorPreset';
import DeleteProject from '../components/DeleteProject';
import EditPost from '../components/EditPost';
import EditProjectModal from '../components/EditProjectModal';
import Posts from '../components/Posts';

type FormData = {
  name: string;
  description: string;
  owner: string;
  collaboratorIDs: string[];
  topicIDs: string[];
  goalIDs: string[];
  status: ProjectStatus;
  isPublic: boolean;
  links: IProjectLink[];
};

function ProjectPage() {
  const isMobile = useMobile();
  const { projectId, workspaceSlug } = useParams<{
    projectId: string;
    workspaceSlug: string;
  }>();
  const navigate = useNavigate();
  const [userId] = useLocalStorage('userId', undefined);
  const baseUrl = `/workspace/${workspaceSlug}/project`;

  const {
    loading: loadingMe,
    data: myData,
    refetch: refreshUserData,
  } = useMe();
  const { projects, removeProject, restoreProject } = useProjectContext();
  const [archiveOneProject] = useArchiveOneProject();
  const [deleteOneProject] = useDeleteOneProject();
  const [updateOneProject] = useUpdateOneProject();

  const { loading, data } = useProject({
    variables: {
      where: {
        id: { equals: projectId },
        workspace: {
          slug: { equals: workspaceSlug },
        },
      },
    },
    onError: (error: any) => {
      toast((t) => <ToastMessage id={t.id} title={`${error.message}-red`} />);
    },
  });

  const [formData, setFormData] = useState<FormData>({
    name: '',
    description: '',
    owner: '',
    collaboratorIDs: [],
    topicIDs: [],
    goalIDs: [],
    status: ProjectStatus.PLANNING,
    isPublic: true,
    links: [],
  });

  const [state, setState] = useState({
    deleteProject: false,
    archiveProject: false,
    editProject: false,
    editPost: false,
    openPanel: false,
    funcType: undefined as 'archive' | 'delete' | undefined,
  });

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const headingContainerRef = useRef<HTMLDivElement | null>(null);
  const [heightHeadingContainerRef, setHeightHeadingContainerRef] = useState(0);
  const isProjectOwner = userId === data?.project.owner.id;

  useEffect(() => {
    if (!loadingMe && !loading && data?.project) {
      const { project } = data;
      setFormData({
        name: project.name,
        description: project.description,
        owner: project.owner.id ?? myData.me.id,
        collaboratorIDs: project.collaboratorIDs,
        topicIDs: project.topicIDs,
        goalIDs: project.goalIDs,
        status: project.status,
        isPublic: project.isPublic,
        links: project.links.map(deleteTypeNameKey),
      });
    }
  }, [loading, data, myData, loadingMe]);

  useEffect(() => {
    const updateHeight = () => {
      if (headingContainerRef.current?.offsetHeight) {
        setHeightHeadingContainerRef(headingContainerRef.current.offsetHeight);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [headingContainerRef.current?.offsetHeight, data?.project]);

  const handleInputChange = (field: string, value: any) => {
    const updatedData = { ...formData, [field]: value };
    setFormData(updatedData);

    updateOneProject({
      variables: {
        data: {
          collaborators: {
            set: updatedData.collaboratorIDs.map((id) => ({ id })),
          },
          owner: { connect: { id: updatedData.owner } },
          // goals: { set: updatedData.goalIDs.map((id) => ({ id })) },
          topics: { set: updatedData.topicIDs.map((id) => ({ id })) },
          isPublic: { set: updatedData.isPublic },
          status: { set: updatedData.status },
          links: {
            upsert: updatedData.links.map((link: IProjectLink) => ({
              create: link,
              update: {
                label: { set: link.label },
                url: { set: link.url },
              },
              where: {
                url_projectId: {
                  projectId: projectId,
                  url: link.url,
                },
              },
            })),
          },
        },
        where: { id: projectId },
      },
      onCompleted: () => {
        toast((t) => (
          <ToastMessage id={t.id} title="Project details updated" />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={error.message} />);
      },
    });
  };

  const handleCopyLink = () => {
    if (data?.project) {
      const origin = window.location.origin;
      const link = `${origin}/workspace/${data.project.workspace.slug}/project/${data.project.id}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title="Link to post is copied to clipboard."
            />
          ));
        })
        .catch(() => {
          toast((t) => (
            <ToastMessage id={t.id} title="Error copying text to clipboard." />
          ));
        });
    }
  };

  const handlePreRequest = (type: 'archive' | 'delete') => {
    removeProject(data.project.id);
    toast((t) => (
      <ToastMessage
        id={t.id}
        title={`This project has been ${type}d.`}
        buttonText={type === 'archive' ? 'Undo' : 'Restore'}
        handleUndo={() => {
          if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            restoreProject(data.project.id);
          }
          toast.dismiss(t.id);
        }}
      />
    ));
    timeoutRef.current = setTimeout(
      () => (type === 'archive' ? handleArchive() : handleFinalDelete()),
      5000
    );
  };

  const handleArchive = () => {
    archiveOneProject({
      variables: { where: { id: data.project.id } },
      onCompleted: () => {
        refreshUserData();
        navigate(baseUrl, {
          replace: true,
        });
        toast(
          (t) => (
            <ToastMessage
              id={t.id}
              title="You can access archived projects from 'Settings'."
            />
          ),
          { duration: 5000 }
        );
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="There was an error archiving this project."
          >
            {error.message}
          </ToastMessage>
        ));
      },
    });
  };

  const handleFinalDelete = () => {
    deleteOneProject({
      variables: { where: { id: data.project.id } },
      onCompleted: () => {
        refreshUserData();
        navigate(baseUrl, {
          replace: true,
        });
        toast((t) => (
          <ToastMessage id={t.id} title="This project has been deleted." />
        ));
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="There was an error deleting this project."
          >
            {error.message}
          </ToastMessage>
        ));
      },
    });
  };

  if (loading || loadingMe)
    return <PageLoader snippet="Loading your user project data and details" />;

  if (!data?.project) {
    navigate(baseUrl, {
      replace: true,
    });
    toast((t) => (
      <ToastMessage id={t.id} title="Project not found">
        Redirecting to workspace projects page...
      </ToastMessage>
    ));
    return null;
  }

  return (
    <Fragment>
      <div className="page-title z-10 flex items-center justify-between md:hidden h-14">
        <IconButton
          icon={<ArrowBack />}
          size={'small'}
          type={'ghost'}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="flex items-center gap-2 capitalize">
          <div>
            <div
              className={clsx(
                'flex items-center justify-center aspect-square h-5 w-5 rounded uppercase text-body-micro bold'
                // sideAvatarClassName // Merge custom className with default classes
              )}
              style={colorStyles[getColorTag(data.project.name)]}
            >
              {data.project.name[0]}
            </div>
          </div>
          <p className="text-body-small bold text-[var(--text-neutral)]">
            {data.project.name}
          </p>
        </div>
        <IconButton
          icon={<Menu />}
          size={'small'}
          type={'ghost'}
          onClick={() => setState({ ...state, openPanel: !state.openPanel })}
        />
      </div>
      {/* Center panel */}
      <section className="w-full flex flex-col gap-2 h-[calc(100%-64px)] md:h-full mt-16 md:mt-0">
        {/* Header Section */}
        <div ref={headingContainerRef}>
          {data?.project && userId && (
            <EditProjectHeading
              userId={userId}
              project={data.project}
              handleEdit={() => setState({ ...state, editProject: true })}
              handleDelete={() => setState({ ...state, deleteProject: true })}
              handleDArchive={() => {
                handlePreRequest('archive');
              }}
              handleCopyLink={handleCopyLink}
            >
              <div className="w-full fixed z-10 bottom-0 left-0 p-4 bg-[var(--backgrounds-on-canvas)] md:static md:z-0 md:!p-0">
                <div className="md:hidden bottom-[72px] absolute w-full left-0">
                  <HorizontalDivider />
                </div>
                <div className="flex relative w-full">
                  <div className="absolute left-0 inset-y-0 px-2 grid place-content-center">
                    <Avatar
                      name={myData.me.detail.fullName}
                      src={myData.me.detail.avatar?.url}
                      loading={loading}
                      alt={`${myData.me.detail.fullName} profile picture at overlap workspace`}
                      size={24}
                    />
                  </div>
                  <TextareaAutosize
                    name="form-field-name"
                    placeholder="What would you like to share?"
                    className="input resize-none bg-none placeholder:text-base placeholder:!font-medium !rounded-full w-full !bg-[var(--backgrounds-default)] !pl-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none"
                    onClick={() => setState({ ...state, editPost: true })}
                    readOnly
                  />
                  <div className="absolute right-0 inset-y-0 px-1.5 grid place-content-center">
                    <IconButton
                      onClick={() => setState({ ...state, editPost: true })}
                      type="primary"
                      size="standard"
                      rounded="full"
                      icon={<ArrowUp />}
                    />
                  </div>
                </div>
              </div>
            </EditProjectHeading>
          )}
        </div>

        {/* <div className="lg:hidden card">
          {workspaceSlug && (
            <EditProjectDetails
              value={formData}
              onChange={handleInputChange}
              workspaceSlug={workspaceSlug}
              projectId={projectId}
              readOnly={!isProjectOwner}
            />
          )}
        </div> */}

        {projectId && workspaceSlug && (
          <Posts
            heightHeadingContainerRef={heightHeadingContainerRef}
            workspaceSlug={workspaceSlug}
            handleCreatePost={() => setState({ ...state, editPost: true })}
            collaborators={data?.project.collaborators}
          />
        )}
      </section>

      {/* Stats */}
      <aside
        className={`absolute z-10 md:z-0 md:sticky right-0 h-screen md:h-[calc(100vh-16px)] md:!translate-x-0 top-0 md:max-w-[406px] w-full transition-all lg:block bg-[rgba(7,7,7,0.5)] md:bg-transparent ${
          isMobile && state.openPanel ? 'slide-in-panel' : 'close-panel'
        }`}
      >
        <SectionWrap className="sticky top-0 h-full p-5 overflow-y-auto max-w-[92%] md:max-w-full md:w-full ml-auto">
          <div className="mb-8 md:hidden">
            <IconButton
              icon={<ArrowBack />}
              size={'small'}
              type={'ghost'}
              onClick={() => setState({ ...state, openPanel: false })}
            />
          </div>
          {workspaceSlug && projectId && (
            // this uses the data from use project hook
            <EditProjectDetails2
              value={formData}
              onChange={handleInputChange}
              workspaceSlug={workspaceSlug}
              projectId={projectId}
              readOnly={!isProjectOwner}
            />
          )}
        </SectionWrap>
      </aside>

      <EditProjectModal
        show={state.editProject}
        project={data?.project}
        handleClose={() => setState({ ...state, editProject: false })}
      />

      <DeleteProject
        show={state.deleteProject}
        project={data?.project}
        handleClose={() => setState({ ...state, deleteProject: false })}
        handlePreArchive={() => {
          handlePreRequest('archive');
        }}
        handlePreDelete={() => {
          handlePreRequest('delete');
        }}
      />

      {/* archive project modal */}
      <ArchiveProject
        show={state.archiveProject}
        project={data?.project}
        handleClose={() => setState({ ...state, archiveProject: false })}
      />

      {workspaceSlug && (
        <EditPost
          show={state.editPost}
          handleClose={() => setState({ ...state, editPost: false })}
          workspaceSlug={workspaceSlug}
          collaborators={data?.project.collaborators}
        />
      )}
    </Fragment>
  );
}

export default ProjectPage;
