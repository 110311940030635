import React, { useState } from 'react';
import { IPostComment } from 'src/lib/types';
// import Avatar from "src/components/Avatar";
import { ChatBubble } from 'src/assets/icons';
import PostCommentItem from 'src/components/PostCommentItem';
import { getUniqueCommentAuthors } from 'src/lib/helpers';
import HorizontalDivider from './ui/HorizontalDivider';

function PostComments({
  comments,
  defaultShow,
  myData,
}: {
  comments: IPostComment[];
  defaultShow: boolean;
  myData: any;
}) {
  // const [show, setShow] = useState(defaultShow);
  const [show, setShow] = useState(true);
  const postCommentAuthors = getUniqueCommentAuthors(comments);

  if (comments.length) {
    return (
      <div className="">
        {/* <div className="flex items-center space-x-1">
          <ul className="flex -space-x-1">
            {postCommentAuthors.map(user => (
              <li key={user.id}>
                <Avatar
                  alt={user.detail.fullName} name={user.detail.fullName} src={user.detail.avatar?.url} size={28} fontSize={16} fontWeight={600}
                />
              </li>
            ))}
          </ul>
          <button
            type="button"
            onClick={() => setShow(!show)}
            className="flex items-center space-x-1"
          >
            <div className="font-medium text-xs text-grey-400">
              {comments.length} comments
            </div>
            {show
              ? <ArrowUpPushupCaretSortSelectArrow size={16} color={(theme.textColor.grey as any)['200']}/>
              : <ArrowDropdownCaretSortSelectArrow size={16} color={(theme.textColor.grey as any)['200']}/>
            }
          </button>
        </div> */}

        {show && (
          <ul className="p-5 flex flex-col gap-5">
            {comments.map((comment, index) => (
              <React.Fragment key={comment.id}>
                <li className="flex flex-col gap-2.5">
                  <PostCommentItem comment={comment} />
                </li>
                {index < comments.length - 1 && <HorizontalDivider />}
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-1 mt-12">
      <ChatBubble className="h-8 w-8" />
      <div className="text-heading-6 medium text-[var(--text-default)]">
        No comments yet
      </div>
      <p className="text-body-small regular text-[var(--text-neutral)]">
        Add the first comment
      </p>
    </div>
  );
}

export default PostComments;
