export const AvatarColorPreset = [
  'avatar-green-pea',
  'avatar-martinique',
  'avatar-rouge',
  'avatar-tuna',
  'avatar-korma',
  'avatar-eastern-blue',
  'avatar-oxford-blue',
  'avatar-meteor',
  'avatar-willow-grove',
  'avatar-ultramarine',
  'avatar-whisper',
  'avatar-honey-flower',
  'avatar-green-leaf',
  'avatar-shiraz',
  'avatar-bunting',
  'avatar-cloud-burst',
  'avatar-kingfisher-daisy',
  'avatar-persian-indigo',
  'avatar-blue-gem',
];

export function getColorPreset(key: number) {
  if (key < AvatarColorPreset.length - 1) {
    return AvatarColorPreset[key];
  } else {
    const presetIndex = key % AvatarColorPreset.length;
    return AvatarColorPreset[presetIndex];
  }
}

export const colorStyles = {
  A: { color: 'var(--tags-a-text)', background: 'var(--tags-a-bg)' },
  B: { color: 'var(--tags-b-text)', background: 'var(--tags-b-bg)' },
  C: { color: 'var(--tags-c-text)', background: 'var(--tags-c-bg)' },
  D: { color: 'var(--tags-d-text)', background: 'var(--tags-d-bg)' },
  E: { color: 'var(--tags-e-text)', background: 'var(--tags-e-bg)' },
  F: { color: 'var(--tags-f-text)', background: 'var(--tags-f-bg)' },
  G: { color: 'var(--tags-g-text)', background: 'var(--tags-g-bg)' },
  H: { color: 'var(--tags-h-text)', background: 'var(--tags-h-bg)' },
};
