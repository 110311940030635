// ToastContext.tsx
import React, { createContext, ReactNode, useContext } from 'react';
import { toast as sonnerToast } from 'sonner';
import { Close } from 'src/assets/icons';

interface ToastContextType {
  showToast: (message: string) => void;
  showSuccessToast: (message: string) => void;
  showErrorToast: (message: string) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const ToastProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  function toastContent({ message }: { message: string }) {
    return (
      <div className="flex w-[380px] h-12 py-3 px-4 items-center justify-between gap-4 rounded-lg bg-[var(--backgrounds-dropdowns)] border-[1px_solid_var(--border-default)] shadow-[0px_3px_2px_0px_rgba(7,_7,_7,_0.05)]">
        <span className="text-body-small medium text-[var(--text-on-bg)]">
          {message}
        </span>
        <div className="close-btn" onClick={() => sonnerToast.dismiss()}>
          <Close />
        </div>
      </div>
    );
  }
  const showToast = (message: string, duration: number = 5000) => {
    sonnerToast.custom(() => toastContent({ message }), {
      duration,
      unstyled: true,
    });
  };

  const showSuccessToast = (message: string) => {
    sonnerToast.success(message);
  };

  const showErrorToast = (message: string) => {
    sonnerToast.error(message);
  };

  return (
    <ToastContext.Provider
      value={{ showToast, showSuccessToast, showErrorToast }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
