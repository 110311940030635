import { AnimatePresence, motion } from 'framer-motion';
import React from 'react';
import Button from '../Button/Button';
import HeaderText from '../HeaderTexts';

interface ModalProps {
  open: boolean;
  showCTA?: boolean;
  data: {
    subtitle: string;
    snippet?: string;
    btn1: string;
    btn2: string;
    btn1Type: 'primary' | 'neutral' | 'ghost' | 'destructive';
    btn2Type: 'primary' | 'neutral' | 'ghost' | 'destructive';
  };
  children?: React.ReactNode;
  onBtn1Click?: () => void; // Function prop for closing modal
  onBtn2Click?: () => void; // Function prop for closing modal
  btn1Disabled?: boolean;
  btn2Disabled?: boolean;
  loading?: boolean;
}

export default function Modal({
  showCTA = true,
  open,
  data,
  children,
  onBtn1Click,
  onBtn2Click,
  btn1Disabled,
  btn2Disabled,
  loading,
}: ModalProps) {
  return (
    <AnimatePresence>
      {open && (
        <div className="fixed w-full h-screen inset-0 grid place-items-center">
          <motion.div
            initial={{ y: 0, opacity: 0, scale: 0.9 }}
            animate={{ y: 0, opacity: 1, scale: 1 }}
            exit={{ y: 5, opacity: 0 }}
            transition={{ duration: 0.1 }}
            className="modal-wrapper p-5 flex flex-col gap-8 absolute z-10 top-32 max-w-[528px] w-[90%] md:w-full"
          >
            <HeaderText subTitle={data.subtitle} snippet={data.snippet} />
            {children} {/* Render the children provided */}
            {showCTA && (
              <div className="flex justify-end gap-3 items-center">
                <Button
                  className={'w-auto'}
                  btnType={data.btn1Type}
                  size="medium"
                  value={data.btn1}
                  onClick={onBtn1Click}
                  disabled={btn1Disabled}
                />
                <Button
                  className={'w-auto'}
                  btnType={data.btn2Type}
                  size="medium"
                  value={data.btn2}
                  onClick={onBtn2Click}
                  disabled={btn2Disabled}
                  loading={loading}
                />
              </div>
            )}
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="modal_overlay w-full h-screen absolute top-0 left-0"
          ></motion.div>
        </div>
      )}
    </AnimatePresence>
  );
}
