import React, { useEffect, useState } from 'react';
// import Avatar from "src/components/Avatar";
import TextareaAutosize from 'react-textarea-autosize';
import { getUniqueCommentAuthors } from 'src/lib/helpers';
import { IPostComment } from 'src/lib/types';
// import Button from "src/components/Button";
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useMe } from 'src/api/auth/useMe';
import { useCreateOnePostComment } from 'src/api/postComment/useCreateOnePostComment';
import { ArrowUp } from 'src/assets/icons';
import PostCommentBody from 'src/components/PostCommentBody';
import PostCommentReplies from 'src/components/PostCommentReplies';
import ReplyCommentBody from './ReplyCommentBody';
import ToastMessage from './ToastMessage';
import Avatar from './ui/Avatar/Avatar';
import Button from './ui/Button/Button';
import IconButton from './ui/Button/IconButton';
import HorizontalDivider from './ui/HorizontalDivider';
import PageLoader from './ui/Loader/PageLoader';
import StatusTag from './ui/tags/StatusTags';

function PostCommentItem({ comment }: { comment: IPostComment }) {
  const [showReplies, setShowReplies] = useState(false);
  const { loading: loadingMe, data: myData } = useMe();
  const [show, setShow] = useState(false);
  const [response, setResponse] = useState('');
  const [createOnePostComment, { loading }] = useCreateOnePostComment();
  const commentAuthors = getUniqueCommentAuthors(comment.responses);

  const [searchParams] = useSearchParams();
  const commentId = searchParams.get('comment');

  useEffect(() => {
    setTimeout(() => {
      setShowReplies(
        comment.responses.findIndex((response) => response.id === commentId) >=
          0
      );
    }, 750);
  }, []);

  const handlePost = (response: string) => () => {
    if (response.length) {
      createOnePostComment({
        variables: {
          data: {
            comment: response,
            parent: {
              connect: { id: comment.id },
            },
            post: {
              connect: { id: comment.post.id },
            },
          },
        },
        optimisticResponse: {
          createOnePostComment: {
            __typename: 'PostComment',
            id: comment.id,
            comment: response,
          },
        },
        refetchQueries: ['Posts'],
        onCompleted: (data) => {
          setResponse('');
          setShow(false);
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={'There was an error posting this comment.'}
            />
          ));
        },
      });
    }
  };

  const [borderRadius, setBorderRadius] = useState(100); // State for border radius

  useEffect(() => {
    const textAreaElement = document.querySelector('.textarea-autosize-reply');

    if (!textAreaElement) return; // Early return if element is not found

    const lineHeight = parseInt(
      window.getComputedStyle(textAreaElement).lineHeight
    );
    const maxLines = 1;

    const adjustBorderRadius = () => {
      const currentHeight = textAreaElement.scrollHeight;
      const currentLines = Math.floor(currentHeight / lineHeight);

      if (currentLines > maxLines) {
        setBorderRadius(8);
      } else {
        setBorderRadius(100);
      }
    };

    textAreaElement.addEventListener('input', adjustBorderRadius);
    adjustBorderRadius(); // Initial check

    return () => {
      textAreaElement.removeEventListener('input', adjustBorderRadius);
    };
  }, [response]);

  if (loadingMe) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="duration-1000 flex flex-col gap-4">
        <PostCommentBody comment={comment} />

        <div
          className="flex items-center gap-1 relative group cursor-pointer"
          onClick={() => setShow(!show)}
        >
          <div className="avatars-group flex items-center -space-x-1">
            {commentAuthors.map((user, key) => (
              <React.Fragment key={key}>
                <div>
                  <Avatar
                    name={user.detail.fullName}
                    size={16}
                    alt={user.detail.fullName}
                    src={user.detail.avatar?.url}
                    className="border border-[var(--backgrounds-on-canvas)]"
                  />
                </div>
              </React.Fragment>
            ))}
          </div>
          <div className="comments">
            <StatusTag
              color={'default'}
              size={'medium'}
              value={comment.responses.length}
              leftIcon={
                <svg
                  className=""
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M4 6.5L2 4.5M2 4.5L4 2.5M2 4.5H8.5C9.32845 4.5 10 5.17155 10 6V9.5"
                    stroke="#8D8D8D"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className=" !fill-none"
                  />
                </svg>
              }
            />
          </div>

          <div className="w-[18px] h-[18px] aspect-square flex flex-col items-center justify-center">
            <svg
              width="4"
              height="5"
              viewBox="0 0 4 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.0032 4.39773C1.65945 4.39773 1.34553 4.31392 1.06143 4.14631C0.777344 3.97585 0.550071 3.74858 0.379617 3.46449C0.212003 3.1804 0.128196 2.86648 0.128196 2.52273C0.128196 2.17614 0.212003 1.86222 0.379617 1.58097C0.550071 1.29687 0.777344 1.07102 1.06143 0.903409C1.34553 0.732954 1.65945 0.647727 2.0032 0.647727C2.34979 0.647727 2.66371 0.732954 2.94496 0.903409C3.22905 1.07102 3.4549 1.29687 3.62251 1.58097C3.79297 1.86222 3.8782 2.17614 3.8782 2.52273C3.8782 2.86648 3.79297 3.1804 3.62251 3.46449C3.4549 3.74858 3.22905 3.97585 2.94496 4.14631C2.66371 4.31392 2.34979 4.39773 2.0032 4.39773Z"
                fill="#8D8D8D"
              />
            </svg>
          </div>

          <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
            <Button value={'Reply'} size={'small'} btnType={'ghost'} />
          </div>
        </div>

        {comment.responses.length > 0 && (
          <div>
            <div className="comments p-4 md:p-6 flex flex-col gap-3 rounded-lg bg-[var(--backgrounds-default)]">
              {comment.responses.map((response, index) => (
                <div key={response.id}>
                  <ReplyCommentBody
                    comment={response}
                    isLastComment={index === comment.responses.length - 1}
                  />
                  {index !== comment.responses.length - 1 && (
                    <div className="pt-3">
                      <HorizontalDivider />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        )}

        {/* <div className="flex items-center divide-x divide-grey-75">
            <button
              onClick={() => setShow(!show)}
              type="button"
              className="px-2.5 flex items-center space-x-0.5 font-medium text-xs text-grey-300"
            >
              <Reply size={12} color={(theme.textColor.grey as any)['300']} weight={2} />
              <span>Reply</span>
            </button>  */}

        {/* {comment.responses.length > 0 && (
          <div className="px-2.5 flex items-center space-x-1">
            <ul className="flex -space-x-1">
              {commentAuthors.map(user => (
                <li key={user.id}>
                  <Avatar
                    name={user.detail.fullName}
                    size={28}
                    fontSize={16}
                    fontWeight={600}
                    alt={user.detail.fullName}
                    src={user.detail.avatar?.url}
                  />
                </li>
              ))}
            </ul>

            <button
              type="button"
              onClick={() => setShowReplies(true)}
              className="flex items-center space-x-1"
            >
              <div className="font-medium text-xs text-grey-300">
                {comment.responses.length} replies
              </div>
              <ChevronRightArrow size={12} color={(theme.textColor.grey as any)['200']} weight={3} />
            </button>
          </div>
        )} */}
      </div>

      {show && (
        <div className="flex sticky bottom-3 w-full">
          <div className="absolute left-0 inset-y-0 px-2 top-2.5 grid place-content-start">
            <Avatar
              name={myData?.me.detail.fullName}
              src={myData?.me.detail.avatar?.url}
              alt={`${myData?.me.detail.fullName} profile picture at overlap workspace`}
              size={24}
            />
          </div>
          <TextareaAutosize
            onChange={(e) => setResponse(e.target.value)}
            disabled={loading}
            name="form-field-name"
            placeholder="Add a reply"
            className={`textarea-autosize-reply input resize-none max-h-[182px] bg-none placeholder:text-base placeholder:!font-medium w-full !bg-[var(--backgrounds-default)] !pl-11 !pr-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none`}
            value={response}
            style={{
              borderRadius: `${borderRadius}px`,
            }}
          />
          <div className="absolute right-2 inset-y-0 bottom-1.5 grid place-content-end">
            <IconButton
              onClick={handlePost(response)}
              type="primary"
              size="standard"
              rounded="full"
              disabled={loading}
              loading={loading}
              icon={<ArrowUp />}
            />
          </div>

          {/* 
            <div className="flex items-center justify-end space-x-3">
              <Button
                onClick={() => setShow(false)}
                type="button"
                size="small" value={''} btnType={'ghost'}              >
                Cancel
              </Button>
              <Button
                onClick={handlePost(response)}
                type="button"
                size="small"
                loading={loading}
                disabled={loading} value={''} btnType={'ghost'}              >
                Reply
              </Button>
            </div> */}
        </div>
      )}
      {/* </div> */}

      <PostCommentReplies
        loading={loading}
        comment={comment}
        handlePost={handlePost}
        show={showReplies}
        onClose={() => setShowReplies(false)}
      />
    </>
  );
}

export default PostCommentItem;
