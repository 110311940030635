import 'src/components/sideNav/sidenav.css';
import { tv } from 'tailwind-variants';

interface BadgeProps {
  value: string | number | undefined;
}

const Badge: React.FC<BadgeProps> = ({ value }) => {
  return (
    <div
      className={`flex items-center justify-center p-1 rounded h-3.5 
        ${badgeStyles()}
      `}
    >
      <span className="text-caption bold text-[var(--backgrounds-on-canvas)]">
        {value}
      </span>
    </div>
  );
};

const badgeStyles = tv({
  base: 'bg-[var(--text-default)]',
});

export default Badge;
