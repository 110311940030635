import { useLogout } from './useLogout';
import { useMemo } from 'react';
import { ApolloClient, ApolloLink, InMemoryCache, split } from '@apollo/client';
import { onError } from 'apollo-link-error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const HTTP_URL = process.env.REACT_APP_API_URL;
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

export const useApolloClient = () => {
  const logout = useLogout();

  return useMemo(() => {
    const httpLink = createUploadLink({
      uri: HTTP_URL + '/graphql',
      headers: {
        'Apollo-Require-Preflight': 'true',
      },
    });

    const logoutLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, locations, path }) =>
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
          );

          const isUnauthorized = graphQLErrors.some(({ extensions }: any) => {
            return extensions?.originalError?.statusCode === 401;
          });

          if (isUnauthorized) {
            logout();
          }
        }
        if (networkError) console.log(`[Network error]: ${networkError}`);
      }
    );

    const wsLink = new GraphQLWsLink(
      createClient({
        url: WEBSOCKET_URL as string,
        // connectionParams: {
        //   authToken: user.authToken,
        // },
      })
    );

    // The split function takes three parameters:
    //
    // * A function that's called for each operation to execute
    // * The Link to use for an operation if the function returns a "truthy" value
    // * The Link to use for an operation if the function returns a "falsy" value
    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      ApolloLink.from([logoutLink, httpLink] as ApolloLink[])
    );

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: splitLink,
      connectToDevTools: true,
    });
  }, [logout]);
};
