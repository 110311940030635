import React from 'react';
import SideNavMobile from 'src/components/sideNav/SideNavMobile';
import useMobile from 'src/hooks/useMobile';
import ComingSoon from 'src/pages/ComingSoon';

export default function FeedsPage() {
  const isMobile = useMobile();
  return (
    <div>
      <ComingSoon />
      {isMobile && <SideNavMobile />}
    </div>
  );
}
