import { useState } from 'react';
// import Button from "src/components/Button";
import toast from 'react-hot-toast';
import { ArrowLink, MegaPhone } from 'src/assets/icons';
import DeletePost from 'src/components/DeletePost';
import EditPost from 'src/components/EditPost';
import PostItem from 'src/components/PostItem';
import ToastMessage from 'src/components/ToastMessage';
import { usePostsContext } from 'src/context/PostContext';
import useMobile from 'src/hooks/useMobile';
import { IPost, IUser } from 'src/lib/types';
import Button from './ui/Button/Button';
import HorizontalDivider from './ui/HorizontalDivider';
import SectionWrap from './ui/SectionWrap';

function Posts({
  // projectId,
  handleCreatePost,
  workspaceSlug,
  heightHeadingContainerRef,
  collaborators = [],
}: {
  // projectId: string;
  workspaceSlug: string;
  handleCreatePost: Function;
  heightHeadingContainerRef: number;
  collaborators?: IUser[];
}) {
  const [editPost, setEditPost] = useState<IPost | undefined>(undefined);
  const [deletePost, setDeletePost] = useState<IPost | undefined>(undefined);

  const isMobile = useMobile();
  const { posts, setPosts } = usePostsContext();
  // const { loading, data } = usePosts({
  //   variables: {
  //     where: {
  //       projectId: { equals: projectId }
  //     },
  //     orderBy: { createdAt: 'desc' }
  //   }
  // });

  // if (loading) return null;

  const handleCopyPost = (post: IPost) => () => {
    const origin = window.location.origin;
    const project = post.project;
    const workspace = project.workspace;
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}?post=${post.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast(
          (t) => (
            <ToastMessage
              id={t.id}
              // visible={t.visible}
              title={'Link to post is copied to clipboard.'}
            />
          ),
          { duration: 50000 }
        );
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            // visible={t.visible}
            title={'Error copying text to clipboard.'}
          />
        ));
      });
  };

  const handleEditPost = (post: IPost) => () => setEditPost(post);

  const handleDeletePost = (post: IPost) => () => setDeletePost(post);

  // const { posts } = data;

  if (posts?.length) {
    return (
      <SectionWrap
        style={{
          height: !isMobile
            ? `calc(100vh - 24px - ${heightHeadingContainerRef}px)`
            : '100%',
        }}
        className="flex flex-col md:max-h-full"
      >
        <>
          {posts.length && (
            <div className="h-full overflow-y-auto pb-20">
              <ul className="flex flex-col h-full flex-grow">
                {posts.map((post: IPost, index: number) => (
                  <li key={`${post.id}-${index}`}>
                    <PostItem
                      post={post}
                      handleCopy={handleCopyPost(post)}
                      handleEdit={handleEditPost(post)}
                      handleDelete={handleDeletePost(post)}
                    />
                    {index !== posts.length - 1 && <HorizontalDivider />}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <EditPost
            post={editPost}
            show={Boolean(editPost)}
            workspaceSlug={workspaceSlug}
            handleClose={() => setEditPost(undefined)}
            collaborators={collaborators}
          />

          <DeletePost
            post={deletePost}
            show={Boolean(deletePost)}
            handleClose={() => setDeletePost(undefined)}
          />
        </>
      </SectionWrap>
    );
  }

  return (
    <SectionWrap className="h-full flex flex-col gap-4">
      <div className="space-y-1 px-6 mt-4">
        <div className="text-body-large medium text-[var(--text-default)]">
          Posts
        </div>
        <div className="text-body-small regular text-[var(--text-neutral)]">
          0 posts
        </div>
      </div>
      <HorizontalDivider />
      <div className="text-center flex justify-center flex-col items-center space-y-6 h-full">
        <MegaPhone className="w-8 h-8" />
        <div className="space-y-2 flex flex-col">
          <div className="text-heading-6 medium text-[var(--text-default)]">
            No posts made yet
          </div>
          <p className="max-w-64 text-body-small regular text-[var(--text-neutral)]">
            Post the first update.
          </p>
        </div>
        <Button
          size="medium"
          btnType="primary"
          icon="right"
          value={'Make a post'}
          className={'w-auto'}
          onClick={() => handleCreatePost()}
        >
          <ArrowLink />
        </Button>
      </div>
    </SectionWrap>
  );
}

export default Posts;
