import React from 'react';
import Badge from '../Badge';
import Avatar from './Avatar';

interface NameAvatarProps {
  src?: string;
  alt?: string;
  name: string;
  position?: string;
  size?: number;
  fontSize?: number;
  loading?: boolean;
  role?: string;
}

const NameAvatar: React.FC<NameAvatarProps> = ({
  src,
  loading = false,
  alt,
  name,
  position,
  role,
  size = 28,
  fontSize = 16,
}) => {
  return (
    <div className="flex items-center gap-2">
      <Avatar loading={loading} name={name} src={src} alt={alt} size={size} />
      <div className="flex flex-col items-start truncate">
        <div className="flex items-center justify-start gap-x-2">
          <span className="text-body-small medium text-[var(--text-default)] truncate max-w-60">
            {name}
          </span>
          {!!role && <Badge value={role} />}
        </div>
        <span className="text-body-micro regular text-[var(--text-neutral)] truncate max-w-72 capitalize">
          {position}
        </span>
      </div>
    </div>
  );
};

export default NameAvatar;
