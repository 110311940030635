import { useMutation } from '@apollo/client';
import { DELETE_ONE_PROJECT } from 'src/lib/mutations';
import { ME } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useDeleteOneProject() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_PROJECT, {
    ...options,
    refetchQueries: [ME],
  });
}
