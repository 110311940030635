import { useQuery } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { ME } from 'src/lib/queries';

export function useMe() {
  const [accessToken] = useLocalStorage('accessToken', undefined);

  const options = accessToken
    ? {
        context: {
          headers: {
            Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
          },
        },
        fetchPolicy: 'network-only',
      }
    : {};

  return useQuery(ME, options as any);
}
