import React, { useState, useMemo } from 'react';
import { useUpdateOneUserEmail } from 'src/api/user/email/useUpdateOneUserEmail';
import { useVerifyUpdateOneUserEmail } from 'src/api/user/email/useVerifyUpdateOneUserEmail';
import { useUpdateOneUserPassword } from 'src/api/user/useUpdateOneUserPassword';
import { useExitOneWorkspace } from 'src/api/user/workspace/useExitOneWorkspace';
import Button from 'src/components/ui/Button/Button';
import HeaderText from "src/components/ui/HeaderTexts";
import HorizontalDivider from "src/components/ui/HorizontalDivider";
import Input from 'src/components/ui/inputs/Default';
import Modal from 'src/components/ui/Modals/Modal';
import { useToast } from 'src/context/ToastContext';
import 'src/pages/settings/settings.css';

interface ModalData {
    subtitle: string;
    snippet?: string;
    btn1: string;
    btn2: string;
    btn1Type: 'neutral' | 'primary';
    btn2Type: 'neutral' | 'primary' | 'destructive';
}

interface ModalDataType {
    email: ModalData;
    verify_email: ModalData;
    change_password: ModalData;
    create_password: ModalData;
    leave_workspace: ModalData;
    verify_leave_workspace: ModalData;
}

interface ModalHeadType {
    type: 'email' | 'verify_email' | 'change_password' | 'create_password' | 'leave_workspace' | 'verify_leave_workspace';
}

interface ProfileSecurityProps {
    userEmail: string;
    userId: string;
    userDetails: any;
}

export default function ProfileSecurity({ userEmail, userId, userDetails }: ProfileSecurityProps) {
    const modalData: ModalDataType = useMemo(() => ({
        email: {
            subtitle: 'Update email address',
            snippet: `You’re currently using <span>${userEmail}.</span>`,
            btn1: 'Cancel',
            btn2: 'Change',
            btn1Type: 'neutral',
            btn2Type: 'primary',
        },
        verify_email: {
            subtitle: 'Verify email address',
            snippet: `Enter the OTP we sent to <span>${userEmail}.</span>`,
            btn1: 'Cancel',
            btn2: 'Verify',
            btn1Type: 'neutral',
            btn2Type: 'primary',
        },
        change_password: {
            subtitle: 'Change your password',
            btn1: 'Cancel',
            btn2: 'Change password',
            btn1Type: 'neutral',
            btn2Type: 'primary',
        },
        create_password: {
            subtitle: 'Create password with this email',
            snippet: 'Create a password to sign in with your email when next you’re logging in.',
            btn1: 'Cancel',
            btn2: 'Create password',
            btn1Type: 'neutral',
            btn2Type: 'primary',
        },
        leave_workspace: {
            subtitle: 'Leave this workspace?',
            snippet: 'Are you sure you want to leave this workspace? Once you leave, all your data will be lost and cannot be regained even when you join back.',
            btn1: 'Cancel',
            btn2: 'Leave workspace',
            btn1Type: 'neutral',
            btn2Type: 'destructive',
        },
        verify_leave_workspace: {
            subtitle: 'Verify this action',
            snippet: `Enter the OTP we just sent to <span>${userEmail}</span> to confirm this action.`,
            btn1: 'Cancel',
            btn2: 'Leave',
            btn1Type: 'neutral',
            btn2Type: 'destructive',
        },
    }), [userEmail]);

    const { showToast, showSuccessToast, showErrorToast } = useToast();
    const [updateOneUserEmail] = useUpdateOneUserEmail();
    const [verifyUpdateOneUserEmail] = useVerifyUpdateOneUserEmail();
    const [exitOneWorkspace] = useExitOneWorkspace();
    const [updateOneUserPassword] = useUpdateOneUserPassword();

    const [feedback, setFeedback] = useState('')
    const [feedbackType, setFeedbackType] = useState<'error' | 'success'>('success')
    const [email, setEmail] = useState('');
    const [OTP, setOTP] = useState('');
    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [createPassword, setCreatePassword] = useState('');

    const [modal, setModal] = useState<ModalData>(modalData.email);
    const [activeModal, setActiveModal] = useState<ModalHeadType>({ type: 'email' });
    const [showModal, setShowModal] = useState(false);

    const handleShowModal = (arg: ModalHeadType) => {
        setShowModal(true);
        setActiveModal(arg);
        setModal(modalData[arg.type]);
    };

    function validateEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    function validatePassword(password: string) {
        return password.length >= 4;
    };

    function clearStates() {
        setFeedback('');
        setFeedbackType('success');
        setEmail('');
        setOTP('');
        setPassword('');
        setNewPassword('');
        setCreatePassword('');
    };

    async function handleCreateOTP() {
        try {
            showToast('Generating OTP, please wait...');
            if (activeModal.type === 'email') {
                await updateOneUserEmail({ variables: { data: { email } } });
                setModal(modalData.verify_email);
                setActiveModal({ type: 'verify_email' });
            } else if (activeModal.type === 'leave_workspace') {
                await exitOneWorkspace({ variables: { where: { id: userId } } });
                setModal(modalData.verify_leave_workspace);
                setActiveModal({ type: 'verify_leave_workspace' });
            }
            showToast('An OTP has been sent to your email');
        } catch (error) {
            showToast('Error generating OTP');
        }
    };

    async function handleEmailChange() {
        try {
            await verifyUpdateOneUserEmail({
                variables: {
                    data: { email, token: OTP },
                    where: { id: userId }
                }
            });
            setShowModal(false);
            showSuccessToast('Email updated successfully');
            setOTP('');
        } catch (error) {
            showToast('Error updating email');
        }
    };

    async function handleChangePassword() {
        try {
            await updateOneUserPassword({
                variables: {
                    data: {
                        oldPassword: { set: password },
                        password: { set: newPassword }
                    },
                    where: { id: userId }
                }
            });
            setShowModal(false);
            setPassword('');
            setNewPassword('');
            showSuccessToast('Password changed successfully');
        } catch (error) {
            showToast('Error changing password');
        }
    };

    async function handleLeaveWorkspace() {
        try {
            await exitOneWorkspace({ variables: { where: { id: userId } } });
            setShowModal(false);
            showSuccessToast('Left workspace successfully');
        } catch (error) {
            showToast('Error leaving workspace');
        }
    };

    async function handleModalFunctionality() {
        switch (activeModal.type) {
            case 'email':
                if (validateEmail(email)) {
                    setFeedbackType('success')
                    setFeedback('')
                    handleCreateOTP();
                } else {
                    setFeedbackType('error')
                    setFeedback('Invalid email address')
                    showToast('Invalid email address');
                }
                break;
            case 'verify_email':
                if (OTP) {
                    handleEmailChange();
                    setFeedback('');
                    setFeedbackType('success'); // Clear feedback and set feedbackType to success
                } else {
                    showToast('Please enter the OTP');
                }
                break;
            case 'change_password':
                if (validatePassword(password) && validatePassword(newPassword)) {
                    handleChangePassword();
                    setFeedback('');
                    setFeedbackType('success'); // Clear feedback and set feedbackType to success
                } else {
                    showToast('Password must be at least 4 characters long');
                }
                break;
            case 'leave_workspace':
                handleCreateOTP();
                break;
            case 'verify_leave_workspace':
                if (OTP) {
                    handleLeaveWorkspace();
                    setFeedback('');
                    setFeedbackType('success'); // Clear feedback and set feedbackType to success
                } else {
                    showToast('Please enter the OTP');
                }
                break;
            case 'create_password':
                if (validatePassword(createPassword)) {
                    // Call the function to create password
                    setFeedback('');
                    setFeedbackType('success'); // Clear feedback and set feedbackType to success
                } else {
                    showToast('Password must be at least 8 characters long');
                }
                break;
            default:
                break;
        }
    };


    const handleDetailsChange = ({ arg, type }: { arg: string; type: string }) => {
        switch (type) {
            case 'email':
                setEmail(arg);
                break;
            case 'OTP':
                setOTP(arg);
                break;
            case 'password':
                setPassword(arg);
                break;
            case 'newPassword':
                setNewPassword(arg);
                break;
            case 'createPassword':
                setCreatePassword(arg);
                break;
            default:
                break;
        }
    };

    return (
        <>
            <HorizontalDivider />
            <div className="flex flex-col items-start gap-6 w-full">
                <HeaderText subTitle="Account security" snippet="You can update your email and password at any time." />
                <div className="section-wrap flex items-center gap-4 justify-between p-4 w-full">
                    <div className="flex flex-col items-start gap-0.5">
                        <p className="title text-body-large medium">Email address</p>
                        <p className="snippet text-body-small regular">{userEmail}</p>
                    </div>
                    <Button btnType="neutral" value="Update" size="medium" className="w-auto" onClick={() => handleShowModal({ type: 'email' })} />
                </div>
                <div className="section-wrap flex items-center gap-4 justify-between p-4 w-full">
                    <div className="flex flex-col items-start gap-0.5">
                        <p className="title text-body-large medium">Change password</p>
                        <p className="snippet text-body-small regular">Change your current password.</p>
                    </div>
                    <Button btnType="neutral" value="Change" size="medium" className="w-auto" onClick={() => handleShowModal({ type: 'change_password' })} />
                </div>
            </div>
            <HorizontalDivider />
            <div className="flex flex-col items-start gap-6 w-full">
                <HeaderText subTitle="Danger zone" snippet="You cannot undo this action if you continue." />
                <div className="section-wrap flex items-center gap-4 justify-between p-4 w-full">
                    <div className="flex flex-col items-start gap-0.5">
                        <p className="title text-body-large medium">Leave workspace</p>
                        <p className="snippet text-body-small regular">Please be very sure about this action before you proceed.</p>
                    </div>
                    <Button btnType="destructive" value="Leave" size="medium" className="w-auto" onClick={() => handleShowModal({ type: 'leave_workspace' })} />
                </div>
            </div>
            <Modal
                open={showModal}
                data={modal}
                onBtn1Click={() => {
                    setShowModal(false)
                    clearStates()
                }}
                onBtn2Click={handleModalFunctionality}
            >
                {activeModal.type !== 'leave_workspace' && <Inputs
                    activeModal={activeModal}
                    OTP={OTP}
                    email={email}
                    password={password}
                    newPassword={newPassword}
                    createPassword={createPassword}
                    feedback={feedback}
                    feedbackType={feedbackType}
                    onChange={handleDetailsChange}
                />}
            </Modal>
        </>
    );
}

function Inputs({
    activeModal,
    OTP,
    email,
    password,
    newPassword,
    createPassword,
    feedback,
    feedbackType,
    onChange,
}: {
    activeModal: ModalHeadType;
    OTP: string;
    email: string;
    password: string;
    newPassword: string;
    createPassword: string;
    feedback: string;
    feedbackType?: 'error' | 'success';
    onChange: ({ arg, type }: { arg: string; type: string }) => void;
}) {
    const inputProps = useMemo(() => {
        switch (activeModal.type) {
            case 'email':
                return { label: 'New email address', placeholder: 'Enter your email address', value: email, type: 'email' };
            case 'verify_email':
            case 'verify_leave_workspace':
                return { label: 'Enter OTP', placeholder: 'Enter your OTP', value: OTP, type: 'OTP' };
            case 'change_password':
                return [
                    { label: 'Current password', placeholder: 'Enter your current password', value: password, type: 'password' },
                    { label: 'Enter new password', placeholder: 'Enter new password', value: newPassword, type: 'newPassword' }
                ];
            case 'create_password':
                return { label: 'Create new password', placeholder: 'Enter a new password', value: createPassword, type: 'createPassword' };
            default:
                return { label: '', placeholder: '', value: '', type: '' };
        }
    }, [activeModal, OTP, email, password, newPassword, createPassword]);

    return (
        <>
            {Array.isArray(inputProps) ? inputProps.map((props, index) => (
                <Input
                    key={index}
                    feedback=""
                    {...props}
                    onChange={(e) => onChange({ arg: e.target.value, type: props.type })}
                />
            )) : (
                <Input
                    feedback={feedback}
                    feedbackType={feedbackType}
                    {...inputProps}
                    onChange={(e) => onChange({ arg: e.target.value, type: inputProps.type })}
                />
            )}
        </>
    );
}
