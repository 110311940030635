import { Fragment, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useMe } from 'src/api/auth/useMe';
import { useInboxCount } from 'src/api/inbox/useInbooxCount';
import { Inbox, LogoNeutral, MegaPhone } from 'src/assets/icons';
import SideNavMenuBtn from 'src/components/sideNav/SideNavMenu';
import { IUserWorkspace } from 'src/lib/types';
import EditProjectModal from '../EditProjectModal';
import InboxPage from '../features/Inbox/InboxPage';
import Badge from '../ui/Badge';
import NavigationHeader from './NavigationHeader';

export default function HomeSideNav() {
  const { workspaceSlug } = useParams();
  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

  const { data: meData } = useMe();
  const userWorkspaces = meData?.me?.userWorkspaces;
  const currentProject = userWorkspaces?.find(
    ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
  );

  const { data: inboxData } = useInboxCount({
    variables: currentProject?.workspace.id
      ? {
          workspaceId: currentProject?.workspace.id,
        }
      : {},
  });
  const unreadInboxCount = inboxData?.inboxCount || 0;

  const [createProject, setCreateProject] = useState(false);
  const [showInbox, setShowInbox] = useState<boolean>(false);

  return (
    <Fragment>
      {/* <div>
        <SideNavMenuBtn type='home' label={'Back'} icon={<ArrowBack />} />
      </div> */}
      <div className="side_nav_group h-full justify-between">
        <div className="flex flex-col gap-6 w-full">
          <div className="menu_btn_group">
            <div
              className={`w-full rounded-lg ${
                showInbox && 'bg-[var(--backgrounds-hover-clicked)]'
              }`}
              onClick={() => setShowInbox(!showInbox)}
            >
              <SideNavMenuBtn
                type="settings"
                label={'Inbox'}
                icon={<Inbox className="w-4 h-4" />}
                showBadge={unreadInboxCount > 0}
                badge={unreadInboxCount}
              />
            </div>
            <Link to={`/workspace/${workspaceSlug}/feeds`}>
              <SideNavMenuBtn
                type="settings"
                label={'Feed'}
                icon={<MegaPhone className="w-4 h-4" />}
                link={`/workspace/${workspaceSlug}/feeds`}
              />
            </Link>
          </div>
          <NavigationHeader
            label="Projects"
            openInbox={createProject}
            baseLink={`${WORKSPACE_URL_BASE}`}
            // projects={userWorkspace?.workspace?.projects || []}
            secNavFunc={() => setCreateProject(true)}
          />
        </div>
        <EditProjectModal
          show={createProject}
          handleClose={() => setCreateProject(false)}
        />
        <InboxPage
          showInbox={showInbox}
          handleClick={() => setShowInbox(false)}
        />
        <div className="w-fit">
          <div className="px-3 gap-2 py-0.5 h-8 flex items-center rounded-lg">
            <div className="">
              <LogoNeutral />
            </div>
            <Badge value={'EARLY ACCESS'} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
