import LogoAnimated from 'src/components/logo/LogoAnimated';

export default function PageLoader({ snippet }: { snippet?: string }) {
  return (
    <div className="absolute top-0 left-0 h-[calc(100svh-42px)] w-full flex items-center justify-center flex-col z-50 gap-5">
      <LogoAnimated className="w-14 h-14" />
      {/* <div className="page_loading_bar"></div> */}
      {/* <div className="text-body-micro regular text-[var(--text-neutral)] -mt-1">{snippet}...</div> */}
    </div>
  );
}
