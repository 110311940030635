import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMe } from 'src/api/auth/useMe';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import useMobile from 'src/hooks/useMobile';
import { IProject, IUserWorkspace } from 'src/lib/types';

interface ProjectContextProps {
  userData: any;
  loading?: boolean;
  projects: IProject[];
  setProjects: React.Dispatch<React.SetStateAction<IProject[]>>;
  removeProject: (projectId: string) => void;
  restoreProject: (projectId: string) => void;
}

const ProjectContext = createContext<ProjectContextProps | undefined>(
  undefined
);

export function useProjectContext() {
  const context = useContext(ProjectContext);

  if (!context) {
    throw new Error(
      'useProjectContext must be used within a ProjectContextProvider'
    );
  }
  return context;
}

interface ProjectContextProviderProps {
  children: ReactNode;
}

export function ProjectContextProvider({
  children,
}: ProjectContextProviderProps) {
  const isMobile = useMobile();
  const { workspaceSlug } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useMe();
  const [userData, setUserData] = useState<any>();
  const [projects, setProjects] = useState<IProject[]>([]);
  const [removedProjects, setRemovedProjects] = useState<
    { project: IProject; index: number }[]
  >([]);

  useEffect(() => {
    if (data && !loading) {
      setUserData(data);
      const userWorkspace = data.me?.userWorkspaces?.find(
        ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
      );
      if (userWorkspace) {
        setProjects(userWorkspace.workspace.projects || []);
      }
    }
  }, [data, loading, workspaceSlug]);

  const removeProject = (projectId: string) => {
    setProjects((prevProjects) => {
      const index = prevProjects.findIndex(
        (project) => project.id === projectId
      );
      if (index === -1) return prevProjects;

      const newRemovedProjects = [
        ...removedProjects,
        { project: prevProjects[index], index },
      ];
      setRemovedProjects(newRemovedProjects);

      const newProjects = prevProjects.filter(
        (project) => project.id !== projectId
      );

      if (newProjects.length > 0) {
        navigate(
          isMobile
            ? `/workspace/${workspaceSlug}/project`
            : `/workspace/${workspaceSlug}/project/${newProjects[0].id}`,
          { replace: true }
        );
      } else {
        navigate(`/workspace/${workspaceSlug}/project`, { replace: true });
      }

      return newProjects;
    });
  };

  const restoreProject = (projectId: string) => {
    setRemovedProjects((prevRemovedProjects) => {
      const removedProjectData = prevRemovedProjects.find(
        ({ project }) => project.id === projectId
      );
      if (!removedProjectData) return prevRemovedProjects;

      setProjects((prevProjects) => {
        const newProjects = [...prevProjects];
        newProjects.splice(
          removedProjectData.index,
          0,
          removedProjectData.project
        );
        return newProjects;
      });

      return prevRemovedProjects.filter(
        ({ project }) => project.id !== projectId
      );
    });
  };

  if (loading) return <PageLoader snippet="Loading user data" />;

  return (
    <ProjectContext.Provider
      value={{
        projects,
        loading,
        setProjects,
        removeProject,
        restoreProject,
        userData,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}
