import { ChangeEvent, useRef, useState } from 'react';
import { useSingleUpload } from 'src/api/useSingleUpload';
import { useUpdateOneUserAvatar } from 'src/api/user/useUpdateOneUserAvatar';
import { Image, Trash } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import DropGroup from 'src/components/ui/DropGroup';
import { useToast } from 'src/context/ToastContext';

interface ProfilePictureProps {
  name: string;
  userId: string;
  profileImageUrl: string | undefined;
  setProfileImageUrl: (event: string | undefined) => void;
}

interface DataItem {
  name: string;
  value: 'change' | 'del';
  icon: JSX.Element;
}

export default function ProfilePicture({
  name,
  profileImageUrl,
  userId,
  setProfileImageUrl,
}: ProfilePictureProps) {
  const data: DataItem[] = [
    {
      name: profileImageUrl ? 'Change image' : 'Add profile image',
      value: 'change',
      icon: <Image />,
    },
    ...(profileImageUrl
      ? ([
          {
            name: 'Remove picture',
            value: 'del',
            icon: <Trash />,
          },
        ] as DataItem[])
      : []),
  ];

  // Toasts definitions
  const { showToast } = useToast();

  // GraphQL definitions
  const [singleUpload] = useSingleUpload();
  const [UpdateOneUserDetail] = useUpdateOneUserAvatar();

  // Profile image modal and input states
  const [toggleProfileImageModal, setToggleProfileImageModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const profileImageInputRef = useRef<HTMLInputElement>(null);

  // function definitions
  function toggleDropGroup() {
    setToggleProfileImageModal(!toggleProfileImageModal);
  }

  function handleDropGroupEvent(item: DataItem) {
    if (item.value === 'change') {
      if (profileImageInputRef.current) {
        profileImageInputRef.current.click();
      }
    } else if (item.value === 'del') {
      delUserAvatar();
      setProfileImageUrl(undefined);
      setToggleProfileImageModal(false);
    }
  }

  function handleImageUpload(event: ChangeEvent<HTMLInputElement>) {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setProfileImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
      toggleDropGroup();
      handleSingleUpload(file);
    }
  }

  async function handleSingleUpload(arg: any) {
    setLoading(true);
    try {
      const { data: result } = await singleUpload({ variables: { file: arg } });
      setProfileImageUrl(result.singleUpload.url);
      updateUserAvatar(result.singleUpload);
      showToast('Profile image uploaded succesfully');
    } catch (error) {
      showToast('Error uploading profile image');
    } finally {
      setLoading(false);
    }
  }

  async function updateUserAvatar(arg: any) {
    setLoading(true);
    try {
      const { data } = await UpdateOneUserDetail({
        variables: {
          data: {
            avatar: {
              set: {
                filename: arg.filename,
                mimetype: arg.mimetype,
                url: arg.url,
              },
            },
          },
          where: {
            id: userId,
          },
        },
      });
      setProfileImageUrl(data.updateOneUserDetail.detail.avatar?.url);
      showToast('Profile image updated succesfully');
    } catch (error) {
      showToast('Error updating profile image');
    } finally {
      setLoading(false);
    }
  }

  async function delUserAvatar() {
    setLoading(true);
    try {
      const { data } = await UpdateOneUserDetail({
        variables: {
          data: {
            avatar: {
              set: {
                filename: '',
                mimetype: '',
                url: '',
              },
            },
          },
          where: {
            id: userId,
          },
        },
      });
      setProfileImageUrl(data.updateOneUserDetail.detail.avatar?.url);
      showToast('Profile image updated succesfully');
    } catch (error) {
      showToast('Error updating profile image');
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="profile relative">
      <Avatar
        name={name}
        src={profileImageUrl}
        loading={loading}
        alt={`${name} profile picture at overlap workspace`}
        size={128}
        onClick={toggleDropGroup}
      />
      <div className="absolute top-24 right-20">
        <DropGroup
          open={toggleProfileImageModal}
          data={data}
          style={{ width: '200px' }}
          onClick={handleDropGroupEvent}
          clickOutside={() => {
            setToggleProfileImageModal(false);
          }}
        />
        <input
          className="hidden"
          type="file"
          ref={profileImageInputRef}
          accept="image/*"
          onChange={handleImageUpload}
        />
      </div>
    </div>
  );
}
