import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { useMe } from 'src/api/auth/useMe';
import { usePosts } from 'src/api/post/usePosts';
import { useCreateOnePostComment } from 'src/api/postComment/useCreateOnePostComment';
import {
  ArrowBack,
  ArrowUp,
  ChevronDown,
  MegaPhone,
  WorkStream,
} from 'src/assets/icons';
import DeletePost from 'src/components/DeletePost';
import EditPost from 'src/components/EditPost';
import PostCommentMenu from 'src/components/PostCommentMenu';
import PostComments from 'src/components/PostComments';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import ImageViewer from 'src/components/ui/ImageViewer';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import SectionWrap from 'src/components/ui/SectionWrap';
import ColorTag from 'src/components/ui/tags/ColorTags';
import StatusTag from 'src/components/ui/tags/StatusTags';
import Timestamp from 'src/components/ui/TimeStamp';
import { transformToTitleCase } from 'src/lib/helpers';
import { IPost } from 'src/lib/types';
import { useProject } from '../api/project/useProject';

export default function CommentPage() {
  const navigate = useNavigate();
  const { loading: loadingMe, data: myData } = useMe();
  const {
    postId: currentPostId,
    projectId,
    workspaceSlug,
  } = useParams<{ postId: string; workspaceSlug: string; projectId: string }>();
  const [userId] = useLocalStorage('userId', undefined);
  const [editPost, setEditPost] = useState<IPost | undefined>(undefined);
  const [deletePost, setDeletePost] = useState<IPost | undefined>(undefined);
  const [focused, setFocused] = useState(false);
  const [comment, setComment] = useState('');
  const [createOnePostComment, { loading: isPostingComment }] =
    useCreateOnePostComment();

  const {
    loading,
    data,
    refetch: refetchPost,
  } = usePosts({
    variables: {
      where: {
        id: { equals: currentPostId },
      },
      orderBy: { createdAt: 'desc' },
    },
  });
  const { loading: loadingProject, data: projectData } = useProject({
    variables: {
      where: { id: projectId },
    },
  });

  const post = data?.posts[0];
  const isPostOwner = userId === post?.author?.id;

  const handleCopyPost = (post: IPost) => () => {
    const origin = window.location.origin;
    const project = post.project;
    const workspace = project.workspace;
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/c/${post.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link to post is copied to clipboard.'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard.'} />
        ));
      });
  };

  const handleEditPost = (post: IPost) => () => setEditPost(post);

  const handleDeletePost = (post: IPost) => () => setDeletePost(post);

  const [searchParams] = useSearchParams();
  const commentId = searchParams.get('comment');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const postId = searchParams.get('post');
  const isFocusedPost = postId === post?.id;
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef2 = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef2.current) {
      // Measure the height of 6 lines
      const lineHeight = parseFloat(
        getComputedStyle(contentRef2.current).lineHeight
      );
      // console.log(parseFloat(getComputedStyle(contentRef2.current).lineHeight), 'parseFloat(getComputedStyle(contentRef2.current).lineHeight)')
      const maxHeight = lineHeight * 6;

      // Check if content height exceeds the height of 6 lines
      // console.log(contentRef2.current.scrollHeight, 'contentRef2.current.scrollHeight')
      if (contentRef2.current.scrollHeight > maxHeight) {
        setIsTruncated(false);
        // setIsTruncated(true);
      }
    }
  }, [post]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setIsHovered(!isHovered);
  };

  const handleCancel = () => {
    requestAnimationFrame(() => {
      setFocused(false);
    });
  };

  const handlePostComment = () => {
    if (comment.length) {
      createOnePostComment({
        variables: {
          data: {
            comment,
            post: {
              connect: { id: post.id },
            },
          },
        },
        onCompleted: () => {
          refetchPost();
          setComment('');
          setFocused(false);
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage id={t.id} title={`Error ${error.message}`} />
          ));
        },
      });
    }
  };

  const [borderRadius, setBorderRadius] = useState(100); // State for border radius

  useEffect(() => {
    const textAreaElement = document.querySelector('.textarea-autosize');

    if (!textAreaElement) return; // Early return if element is not found

    const lineHeight = parseInt(
      window.getComputedStyle(textAreaElement).lineHeight
    );
    const maxLines = 1;

    const adjustBorderRadius = () => {
      const currentHeight = textAreaElement.scrollHeight;
      const currentLines = Math.floor(currentHeight / lineHeight);

      if (currentLines > maxLines) {
        setBorderRadius(8);
      } else {
        setBorderRadius(100);
      }
    };

    textAreaElement.addEventListener('input', adjustBorderRadius);
    adjustBorderRadius(); // Initial check

    return () => {
      textAreaElement.removeEventListener('input', adjustBorderRadius);
    };
  }, [comment]);

  useEffect(() => {
    if (isFocusedPost && componentRef.current instanceof HTMLElement) {
      const element = componentRef.current;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      if (!commentId) {
        element.classList.add('bg-warning-50');
        setTimeout(() => {
          element.classList.remove('bg-warning-50');
        }, 2000);
      }
    }
  }, [isFocusedPost]);

  if (loading || !post || loadingMe)
    return <PageLoader snippet="Loading posts details and comments" />;

  return (
    <>
      <SectionWrap className="w-full md:max-w-[70%] overflow-auto">
        {/* <div> */}
        <div className="flex items-start gap-4 px-6 py-4">
          <IconButton
            icon={<ArrowBack />}
            size={'standard'}
            type={'ghost'}
            onClick={() => navigate(-1)}
          />
          <HeaderText
            subTitle="Comments"
            snippet={`${post?.comments?.length} comments`}
          />
        </div>
        <HorizontalDivider />
        <div className="p-6 flex flex-col items-start gap-6 w-full">
          <div className="flex items-center space-x-6 w-full">
            <div className="flex-1 flex space-x-2 items-center">
              <Avatar
                name={post.author.detail.fullName}
                src={post.author.detail.avatar?.url}
                loading={loading}
                alt={`${post.author.detail.fullName} profile picture at overlap workspace`}
                size={28}
              />
              <div className="">
                <div className="text-body-small medium text-[var(--text-default)] capitalize">
                  {post.author.detail.fullName}
                </div>
                <div className="text-[var(--text-neutral)] text-body-micro regular">
                  {/* Posted {formatDistance(parseISO(post.createdAt), new Date(), {
                                            addSuffix: true,
                                        })} */}
                  {/* {formatRelativeTime(post.createdAt)} */}
                  <Timestamp createdAt={post.createdAt} />
                </div>
              </div>
            </div>

            <div className="flex gap-6 items-center">
              <ul className="flex gap-2 items-start">
                {post.topic && (
                  <li>
                    <ColorTag
                      value={post.topic.name}
                      size="medium"
                      type="filled"
                      color="A"
                      leftIcon={<WorkStream />}
                    />
                  </li>
                )}
                {post.type && (
                  <li>
                    <StatusTag
                      value={transformToTitleCase(post.type)}
                      size="medium"
                      type="filled"
                      color="default"
                      leftIcon={<MegaPhone className="w-3 h-3" />}
                    />
                  </li>
                )}
              </ul>

              <PostCommentMenu
                canEdit={isPostOwner}
                canArchive={isPostOwner}
                canDelete={isPostOwner}
                handleCopy={handleCopyPost(post)}
                handleEdit={handleEditPost(post)}
                handleDelete={handleDeletePost(post)}
              />
            </div>
          </div>

          <div className="space-y-2 w-full">
            <h2 className="text-heading-6 medium text-[var(--text-default)]">
              {post.title}
            </h2>

            <div className="w-full relative">
              <article
                ref={contentRef2}
                dangerouslySetInnerHTML={{ __html: post.comment }}
                className={`ck ck-content text-[var(--text-default)] ${
                  isExpanded ? '' : 'article truncate-lines-'
                }`}
              ></article>
              {isTruncated && (
                <div
                  className="absolute bottom-0 h-[72px] w-full flex flex-col items-center justify-end"
                  style={{
                    background:
                      !isExpanded || isHovered ? 'var(--truncate-bg)' : 'none',
                  }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                >
                  <AnimatePresence>
                    {isExpanded && isHovered && (
                      <motion.div
                        initial={{ y: 10, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 10, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <IconButton
                          icon={<ChevronDown className="rotate-180" />}
                          size={'standard'}
                          type={'neutral'}
                          rounded="full"
                          onClick={toggleExpand}
                        />
                      </motion.div>
                    )}

                    {!isExpanded && (
                      <IconButton
                        icon={<ChevronDown />}
                        size={'standard'}
                        type={'neutral'}
                        onClick={toggleExpand}
                      />
                    )}
                  </AnimatePresence>
                </div>
              )}
            </div>
          </div>

          {post.attachments.length > 0 && (
            <ul className="flex space-x-2 w-full">
              {post.attachments.map(
                (
                  media: { url: any; filename: string | undefined },
                  key: number
                ) => (
                  <li key={key} className="flex-1">
                    <img
                      onClick={() => {
                        setShowImageModal(true);
                        setCurrentImage(key);
                      }}
                      className="aspect-square bg-grey-100 rounded-lg object-cover"
                      src={[process.env.REACT_APP_API_URL, media.url].join('/')}
                      alt={media.filename}
                    />
                  </li>
                )
              )}
            </ul>
          )}

          {showImageModal && (
            <ImageViewer
              images={post.attachments}
              currentImage={currentImage}
              setCurrentImage={(e: number) => setCurrentImage(e)}
              setShowImageModal={() => setShowImageModal(false)}
            />
          )}

          <div className="space-y-2 w-full">
            <div className="flex relative w-full">
              <div className="absolute left-0 inset-y-0 px-2 top-2.5 grid place-content-start">
                <Avatar
                  name={myData.me.detail.fullName}
                  src={myData.me.detail.avatar?.url}
                  loading={loading}
                  alt={`${myData.me.detail.fullName} profile picture at overlap workspace`}
                  size={24}
                />
              </div>
              <TextareaAutosize
                onChange={(e) => setComment(e.target.value)}
                disabled={loading}
                name="form-field-name"
                placeholder="Leave a comment"
                className={`textarea-autosize input placeholder:pt-[2px] resize-none max-h-[182px] bg-none placeholder:text-base placeholder:!font-medium w-full !bg-[var(--backgrounds-default)] !pl-11 !pr-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none scrollbar-hidden`}
                value={comment}
                style={{
                  borderRadius: `${borderRadius}px`,
                }}
              />

              <div className="absolute right-[5px] inset-y-0 bottom-[5px] grid place-content-end">
                <IconButton
                  onClick={() => handlePostComment()}
                  type="primary"
                  size="standard"
                  rounded="full"
                  disabled={isPostingComment}
                  loading={isPostingComment}
                  icon={<ArrowUp />}
                />
              </div>
            </div>
            {/* <div className="space-y-4">
                            <TextareaAutosize
                                value={comment}
                                onChange={e => setComment(e.target.value)}
                                onFocus={() => setFocused(true)}
                                disabled={loading}
                                placeholder="Comment on this post"
                                className="resize-none w-full bg-grey-25 focus:bg-white placeholder:text-grey-200 text-sm shadow-none !border-grey-25"
                            />

                            {focused && (
                                <div className="flex items-center justify-end space-x-3">
                                    <Button
                                        onClick={handleCancel}
                                        type="button"
                                        size="small"
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={handlePostComment}
                                        type="button"
                                        color="primary"
                                        size="small"
                                        loading={loading}
                                        disabled={loading}
                                    >
                                        Post comment
                                    </Button>
                                </div>
                            )}
                        </div> */}
          </div>
        </div>
        <HorizontalDivider />
        {/* </div> */}
        <PostComments
          myData={myData}
          comments={post.comments}
          defaultShow={Boolean(isFocusedPost && commentId)}
        />
        <EditPost
          post={editPost}
          show={Boolean(editPost)}
          workspaceSlug={workspaceSlug ? workspaceSlug : ''}
          handleClose={() => setEditPost(undefined)}
          collaborators={projectData?.project.collaborators}
        />
        <DeletePost
          post={deletePost}
          show={Boolean(deletePost)}
          handleClose={() => setDeletePost(undefined)}
        />
      </SectionWrap>
    </>
  );
}
