import React, { ReactNode, useRef, useState } from 'react';
import './sidenav.css';

export default function DefaultLayout({ children }: { children: ReactNode }) {
  const sideNavRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);

  let startX: number;
  let startWidth: number;

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // console.log('start', window.getComputedStyle(sideNavRef.current!).width)
    // console.log('e.clientX', e.clientX)
    // console.log('e.', e)
    startX = e.clientX;
    startWidth = parseInt(
      window.getComputedStyle(sideNavRef.current!).width,
      10
    );
    // console.log(startWidth)
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    const dx = e.clientX - startX;
    const newWidth = startWidth + dx;

    if (newWidth >= 244) {
      // Adjust minimum width as needed
      sideNavRef.current!.style.width = `${newWidth}px`;
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const [navType, setNavType] = useState<'' | 'SETTIGNS'>();

  return (
    <div ref={sideNavRef} className="side_nav_wrapper h-full">
      <div
        ref={resizerRef}
        onMouseDown={handleMouseDown}
        className="resizer"
      ></div>
      {children}
    </div>
  );
}
