import { useMutation } from '@apollo/client';
import { useLocalStorage } from '@uidotdev/usehooks';
import { CREATE_ONE_PROJECT } from 'src/lib/mutations';

export function useCreateOneProject() {
  const [accessToken] = useLocalStorage('accessToken', undefined);

  const options = accessToken
    ? {
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        },
      }
    : {};

  return useMutation(CREATE_ONE_PROJECT, {
    ...options,
    refetchQueries: ['Project'],
  });
}
