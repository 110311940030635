import { useQuery, useLazyQuery } from '@apollo/client';
import { useApolloOptions } from '../api';
import { WORKSPACE } from 'src/lib/queries';

export function useWorkspace(args: object) {
  const options = useApolloOptions();

  return useQuery(WORKSPACE, { ...options, ...args });
}

export function useLazyWorkspace() {
  const options = useApolloOptions();

  return useLazyQuery(WORKSPACE, options);
}
