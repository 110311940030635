// Spinner.tsx
import React from 'react';
import './spinner.css';
import { tv } from 'tailwind-variants';
import { useTheme } from 'src/context/ThemeContext';
import { getCurrentTheme } from 'src/lib/helpers';

interface SpinnerProps {
  spinnnerSize?: 'medium' | 'large';
}

const Spinner: React.FC<SpinnerProps> = ({ spinnnerSize = 'medium' }) => {
  const { theme } = useTheme();
  const currentTheme = getCurrentTheme(theme);
  const containerClass =
    spinnnerSize === 'large' ? 'spin_container-large' : 'spin_container-medium';

  return (
    <div className={`${containerClass}`}>
      <div
        className={`ispinner ${
          spinnnerSize === 'large' ? 'ispinner-large' : ''
        }`}
      >
        {Array.from({ length: 8 }).map((_, i) => (
          <div
            key={i}
            className={`ispinner_blade 
            ${spinnerStyles({ color: currentTheme })}`}
          ></div>
        ))}
      </div>
    </div>
  );
};

const spinnerStyles = tv({
  base: 'font-medium bg-blue-500 text-white rounded-full active:opacity-80',
  variants: {
    color: {
      light: 'bg-[#8e8e93]',
      dark: 'bg-white',
    },
  },
});

export default Spinner;
