import { Menu, Transition } from '@headlessui/react';
import { format } from 'date-fns';
import { ChangeEvent, Fragment, useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { Google, Slack, Teams, ThreeDots } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import InfoSwitchCard from 'src/components/ui/InfoSwitchCard';
import Input from 'src/components/ui/inputs/Default';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import Modal from 'src/components/ui/Modals/Modal';
import SwitchToggle from 'src/components/ui/ToggleSwitch';
import Validator from 'validatorjs';
import { useCreateOneWorkspaceDomain } from '../../../api/workspace/useCreateOneWorkspaceDomain';
import { useDisableOneWorkspaceDomain } from '../../../api/workspace/useDisableOneWorkspaceDomain';
import { useEnableOneWorkspaceDomain } from '../../../api/workspace/useEnableOneWorkspaceDomain';
import { useRemoveOneWorkspaceDomain } from '../../../api/workspace/useRemoveOneWorkspaceDomain';
import { useSendOneWorkspaceDomainToken } from '../../../api/workspace/useSendOneWorkspaceDomainToken';
import { useUpdateOneWorkspace } from '../../../api/workspace/useUpdateOneWorkspace';
import { useVerifyOneWorkspaceDomain } from '../../../api/workspace/useVerifyOneWorkspaceDomain';
import { useWorkspace } from '../../../api/workspace/useWorkspace';
import Button from '../../../components/ui/Button/Button';
import IconButton from '../../../components/ui/Button/IconButton';
import { DropItem } from '../../../components/ui/DropGroup';
import { useToast } from '../../../context/ToastContext';
import { IWorkspace, IWorkspaceDomain } from '../../../lib/types';

const data = [
  {
    type: 'Google',
    svg: <Google />,
    title: 'Sign up with Google',
    snippet: 'Allow anyone with google email to sign up.',
  },
  {
    type: 'Slack',
    svg: <Slack />,
    title: 'Sign up with Slack',
    snippet: 'Allow anyone with google email to sign up.',
  },
  {
    type: 'Teams',
    svg: <Teams />,
    title: 'Sign up with Teams',
    snippet: 'Allow anyone with google email to sign up.',
  },
];

type FormData = {
  domain: string;
  email: string;
};

Validator.register(
  'ends_with',
  function (value, requirement, attribute) {
    // requirement parameter defaults to null
    return (value as string).endsWith(requirement);
  },
  'The :attribute must end with the above domain.'
);

export default function Security() {
  const { workspaceSlug } = useParams<{
    projectId: string;
    workspaceSlug: string;
  }>();
  const queryVariables = {
    where: { slug: workspaceSlug },
  };
  const { loading: workspaceLoading, data: workspaceData } = useWorkspace({
    variables: queryVariables,
  });
  const [updateOneWorkspace] = useUpdateOneWorkspace();
  const [
    createOneWorkspaceDomain,
    {
      loading: createOneWorkspaceDomainLoading,
      error: createOneWorkspaceDomainError,
    },
  ] = useCreateOneWorkspaceDomain();
  const [removeOneWorkspaceDomain] = useRemoveOneWorkspaceDomain();
  const [enableOneWorkspaceDomain] = useEnableOneWorkspaceDomain();
  const [disableOneWorkspaceDomain] = useDisableOneWorkspaceDomain();
  const [
    verifyOneWorkspaceDomain,
    { loading: verifyOneWorkspaceDomainLoading },
  ] = useVerifyOneWorkspaceDomain();
  const [sendOneWorkspaceDomainToken] = useSendOneWorkspaceDomainToken();

  const { showToast, showSuccessToast, showErrorToast } = useToast();

  // formData
  const [formData, setFormData] = useState<FormData>({
    domain: '',
    email: '',
  });
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);

  // inputs states
  const [verifyEmail, setVerifyEmail] = useState('');
  const [verifyToken, setVerifyToken] = useState('');

  // toggle
  const [checkedItems, setCheckedItems] = useState<{ [key: string]: boolean }>({
    Google: true, // Google checked and disabled
    Slack: false,
    Teams: false,
  });

  // Modal states
  const [addDomainModal, showAddDomainModal] = useState(false);
  const [verifyDomainModal, showVerifyDomainModal] = useState(false);

  function handleSwitchToggle(type: string) {
    setCheckedItems((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  }

  const handleInputChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;

      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      setFormData({ ...formData, [field]: value });
      setFormErrors(null);
    };

  const handleToggleVerifiedDomains = () => {
    updateOneWorkspace({
      variables: {
        data: {
          enableVerifiedDomains: {
            set: !workspaceData?.workspace.enableVerifiedDomains,
          },
        },
        where: {
          slug: workspaceSlug,
        },
      },
      refetchQueries: ['Workspace'],
      optimisticResponse: {
        updateOneWorkspace: {
          ...workspaceData?.workspace,
          enableVerifiedDomains:
            !workspaceData?.workspace.enableVerifiedDomains,
        },
      },
      onCompleted: ({ updateOneWorkspace }) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`Verified domains has been ${
              updateOneWorkspace.enableVerifiedDomains ? 'enabled' : 'disabled'
            } successfully.`}
          />
        ));
        // if (updateOneWorkspace.domains.length === 0) {
        //   showAddDomainModal(true);
        // }
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleSubmitDomain = async () => {
    const validation = new Validator(formData, {
      domain: 'required',
      email: 'required|email|ends_with:' + formData.domain,
    });

    if (validation.passes()) {
      setFormErrors(null);
      createOneWorkspaceDomain({
        variables: {
          data: {
            domain: formData.domain,
            verificationEmail: formData.email,
          },
          where: {
            slug: workspaceSlug,
          },
        },
        onCompleted: ({ createOneWorkspaceDomain }) => {
          setVerifyEmail(formData.email);
          showAddDomainModal(false);
          showVerifyDomainModal(true);
        },
        onError: (error) => {
          toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
        },
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  const handleRemoveDomain = (domain: IWorkspaceDomain) => {
    removeOneWorkspaceDomain({
      variables: {
        data: {
          domain: domain.domain,
          verificationEmail: domain.verificationEmail,
        },
        where: {
          slug: workspaceSlug,
        },
      },
      refetchQueries: ['Workspace'],
      onCompleted: ({ removeOneWorkspaceDomain }) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`Domain has been removed successfully.`}
          />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  const handleToggleDomain = (domain: IWorkspaceDomain) => {
    const variables = {
      data: {
        domain: domain.domain,
        verificationEmail: domain.verificationEmail,
      },
      where: {
        slug: workspaceSlug,
      },
    };

    if (domain.verifiedAt) {
      const newEnabledState = !domain.enabled;
      // console.log('Current state:', domain.enabled);
      // console.log('New state:', newEnabledState);

      const toggleOneWorkspaceDomain = newEnabledState
        ? disableOneWorkspaceDomain
        : enableOneWorkspaceDomain;

      toggleOneWorkspaceDomain({
        variables,
        refetchQueries: ['Workspace'],
        onCompleted: (data) => {
          // console.log('Mutation completed:', data);

          const updatedWorkspace =
            data.enableOneWorkspaceDomain || data.disableOneWorkspaceDomain;

          if (updatedWorkspace) {
            console.log('Updated workspace:', updatedWorkspace);

            const updatedDomain = updatedWorkspace.domains.find(
              (d: any) => d.domain === domain.domain
            );

            if (updatedDomain) {
              console.log('Updated domain:', updatedDomain);

              toast((t) => (
                <ToastMessage
                  id={t.id}
                  title={`Domain has been ${
                    updatedDomain.enabled ? 'enabled' : 'disabled'
                  } successfully.`}
                />
              ));
            } else {
              console.error('Updated domain not found in the response');
            }
          } else {
            console.error('Unexpected mutation result:', data);
          }

          // const updatedDomain = newEnabledState
          //   ? data.enableOneWorkspaceDomain
          //   : data.disableOneWorkspaceDomain;
          // console.log('Updated domain:', updatedDomain);

          // toast((t) => (
          //   <ToastMessage
          //     id={t.id}
          //     title={`Domain has been ${
          //       newEnabledState ? 'enabled' : 'disabled'
          //     } successfully.`}
          //   />
          // ));
        },
        onError: (error) => {
          toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
        },
      });
    } else {
      sendOneWorkspaceDomainToken({
        variables,
        onCompleted: () => {
          setVerifyEmail(domain.verificationEmail);
          showVerifyDomainModal(true);
        },
      });
    }
  };

  const handleVerifyDomain = () => {
    verifyOneWorkspaceDomain({
      variables: {
        data: {
          email: verifyEmail,
          token: verifyToken,
        },
        where: {
          slug: workspaceSlug,
        },
      },
      onCompleted: ({ removeOneWorkspaceDomain }) => {
        setVerifyEmail('');
        setVerifyToken('');
        showVerifyDomainModal(false);
        toast((t) => (
          <ToastMessage id={t.id} title={`Domain has been verified.`} />
        ));
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  if (workspaceLoading)
    return (
      <PageLoader snippet="Loading your workspace's projects, and posts" />
    );

  const workspace: IWorkspace = workspaceData?.workspace;
  const domains = workspace?.domains ?? [];

  return (
    <div className="workspace-page flex flex-col gap-10">
      <div className="header_div">
        <HeaderText title="Security" />
        <HorizontalDivider />
      </div>
      <section className="flex flex-col items-start gap-6">
        <HeaderText
          subTitle="Allow these domains"
          snippet="Manage how members sign up to your workspace."
        />
        <div className="w-full flex flex-col items-start gap-8">
          {data.map((item) => (
            <InfoSwitchCard
              key={item.type}
              checked={checkedItems[item.type] || false}
              disabled={item.type === 'Google' || true}
              type={item.type}
              svg={item.svg}
              title={item.title}
              snippet={item.snippet}
              onSwitch={handleSwitchToggle}
            />
          ))}
        </div>
      </section>
      <HorizontalDivider />
      <section className="space-y-5">
        <div className="flex items-center w-full justify-between gap-4">
          <HeaderText
            subTitle="Verified domain"
            snippet="Anyone with an email address from these domains can sign up to join the workspace."
          />
          <SwitchToggle
            id="toggle1"
            isChecked={workspace?.enableVerifiedDomains}
            onChange={handleToggleVerifiedDomains}
            label="Toggle 1"
            className="toggle-container"
          />
        </div>
        {workspace?.enableVerifiedDomains && (
          <Fragment>
            {domains.map((domain) => (
              <div
                key={domain.id}
                className="section-wrap flex items-center gap-4 justify-between p-4 w-full"
              >
                <div className="flex flex-col items-start gap-0.5">
                  <p className="title text-body-large medium">
                    {domain.domain}
                  </p>
                  <p className="snippet text-body-small regular">
                    You added this on {format(domain.createdAt, 'dd-MM-yyyy')}
                  </p>
                </div>
                <div className="flex items-center space-x-2">
                  <SwitchToggle
                    id={domain.id}
                    isChecked={domain.enabled}
                    onChange={() => handleToggleDomain(domain)}
                    label={domain.domain}
                    className="toggle-container"
                  />

                  <Menu as="div" className="relative inline-block text-left">
                    {({ open }) => (
                      <>
                        <div>
                          <Menu.Button>
                            <IconButton
                              size="small"
                              type="ghost"
                              state={open ? 'open' : undefined}
                              icon={<ThreeDots />}
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="drop-group absolute z-10 right-0 mt-2 w-48 origin-top-right">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <button>
                                    <DropItem
                                      item={{ name: 'Remove domain' }}
                                      onItemClick={() =>
                                        handleRemoveDomain(domain)
                                      }
                                    />
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </>
                    )}
                  </Menu>
                </div>
              </div>
            ))}
            <Button
              btnType={'primary'}
              value={'Add domain'}
              size={'medium'}
              className={'w-auto ml-auto'}
              onClick={() => showAddDomainModal(true)}
            />
          </Fragment>
        )}
      </section>

      {/* Add domain modal */}
      <Modal
        open={addDomainModal}
        data={{
          subtitle: 'Add domain',
          btn1: 'Cancel',
          btn2: 'Add domain',
          btn1Type: 'neutral',
          btn2Type: 'primary',
        }}
        onBtn1Click={() => {
          showAddDomainModal(false);
          if (domains.length === 0) {
            handleToggleVerifiedDomains();
          }
        }}
        onBtn2Click={() => {
          handleSubmitDomain();
        }}
        btn2Disabled={createOneWorkspaceDomainLoading}
        loading={createOneWorkspaceDomainLoading}
      >
        <Input
          type="text"
          feedback={formErrors ? formErrors['domain'] : ''}
          feedbackType={
            formErrors && formErrors['domain'] ? 'error' : 'success'
          }
          label="Domain"
          placeholder="Enter your domain"
          value={formData.domain}
          onChange={handleInputChange('domain')}
        />
        <Input
          type="email"
          feedback={
            formErrors
              ? formErrors['email']
              : 'Email must end with the above domain'
          }
          feedbackType={formErrors && formErrors['email'] ? 'error' : 'success'}
          label="Email to verify"
          placeholder="Enter your email address"
          value={formData.email}
          onChange={handleInputChange('email')}
        />
        {createOneWorkspaceDomainError && (
          <p className="text-xs text-red-500">
            {createOneWorkspaceDomainError.message}
          </p>
        )}
      </Modal>

      {/* Verify domain modal */}
      <Modal
        open={verifyDomainModal}
        data={{
          subtitle: 'Verify domain',
          snippet:
            'Enter the OTP we just sent to ' +
            verifyEmail +
            ', kindly verify below to add your domain.',
          btn1: 'Cancel',
          btn2: 'Verify domain',
          btn1Type: 'ghost',
          btn2Type: 'primary',
        }}
        onBtn1Click={() => {
          showVerifyDomainModal(false);
        }}
        onBtn2Click={() => {
          handleVerifyDomain();
        }}
        btn2Disabled={
          verifyToken.length === 0 || verifyOneWorkspaceDomainLoading
        }
        loading={verifyOneWorkspaceDomainLoading}
      >
        <Input
          type="text"
          value={verifyToken}
          feedback=""
          label="Enter OTP"
          placeholder="Enter your OTP"
          onChange={(e) => setVerifyToken(e.target.value)}
        />
      </Modal>
    </div>
  );
}
