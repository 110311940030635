import { useMutation } from '@apollo/client';
import { useApolloOptions } from '../api';
import { ME } from 'src/lib/queries';
import { UPDATE_ONE_WORKSPACE_LOGO } from 'src/lib/mutations';

export function useUpdateOneWorkspaceLogo() {
  const options = useApolloOptions();
  return useMutation(UPDATE_ONE_WORKSPACE_LOGO, {
    ...options,
    refetchQueries: [{ query: ME, ...options }],
  });
}
