import { Popover, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useWorkspace } from 'src/api/workspace/useWorkspace';
import { fuzzySearchRegex, transformToTitleCase } from 'src/lib/helpers';
import { IUser, IUserWorkspace } from 'src/lib/types';
// import Avatar from "src/components/Avatar";
import { useDebounce } from '@uidotdev/usehooks';
import classNames from 'classnames';
import { useProject } from 'src/api/project/useProject';
import { Check, Plus } from 'src/assets/icons';
import Avatar from './ui/Avatar/Avatar';
import HorizontalDivider from './ui/HorizontalDivider';

interface ProjectCollaboratorsSelectProps {
  onChange: Function;
  projectId?: string;
  userId?: string | null;
  value: string[];
  workspaceSlug: string;
  readOnly?: boolean;
}

const ProjectCollaboratorsSelect: React.FC<ProjectCollaboratorsSelectProps> = (
  props
) => {
  const {
    projectId,
    value,
    onChange,
    workspaceSlug,
    userId,
    readOnly = true,
  } = props;
  const [searchTerm, setSearchTerm] = React.useState('');
  const [selectedOptions, setSelectedOptions] = useState<IUser[]>([]);

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const { loading, data } = useWorkspace({
    variables: {
      where: { slug: workspaceSlug },
    },
  });

  const { loading: projectLoading, data: projectData } = useProject({
    variables: projectId
      ? {
          where: {
            id: projectId,
            workspace: {
              slug: workspaceSlug,
            },
          },
        }
      : null,
  });

  const workspaceCollaborators = useMemo(() => {
    if (!data?.workspace?.members) return [];

    const regex = fuzzySearchRegex(debouncedSearchTerm);
    return data.workspace.members
      .map((member: IUserWorkspace) => member.user)
      .filter((user: IUser) =>
        debouncedSearchTerm.length ? regex.test(user.detail.fullName) : true
      );
  }, [data?.workspace?.members, debouncedSearchTerm]);

  useEffect(() => {
    if (workspaceCollaborators.length) {
      setSelectedOptions(
        workspaceCollaborators.filter(
          (user: IUser) =>
            value?.includes(user.id) || (userId && user.id === userId)
        )
      );
    }
  }, [workspaceCollaborators, value, userId]);

  const handleCheckboxChange = (option: IUser) => {
    const newSelectedOptions = selectedOptions.includes(option)
      ? selectedOptions.filter((item) => item !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newSelectedOptions);
    onChange(newSelectedOptions.map(({ id }) => id));
  };

  if (loading) return null;

  const maxItemCount = 10;
  const collaboratorOptionsLength = selectedOptions.filter((each: IUser) => {
    if (userId && !projectId) {
      return each.id !== userId;
    }
    return true;
  }).length;

  const button = collaboratorOptionsLength ? (
    <ul className="flex items-center -space-x-2 rounded-lg w-fit hover:bg-[var(--backgrounds-default)] p-1">
      {selectedOptions.slice(0, maxItemCount).map((option, index) => (
        <li
          key={option.id}
          className={`relative rounded-full ${
            index > 0 ? 'border border-[var(--backgrounds-default)]' : ''
          }`}
          style={{ zIndex: index }}
        >
          <Avatar
            name={option.detail.fullName}
            src={option.detail?.avatar?.url}
            alt={option.detail.fullName}
            size={20}
          />
        </li>
      ))}
      {selectedOptions.length > maxItemCount && (
        <li>
          <div className="h-5 pl-2 font-medium text-grey-300 text-xs grid place-content-center">
            {selectedOptions.length - maxItemCount}+
          </div>
        </li>
      )}
      {/* {Boolean(projectId) || (
          <li className="pl-3">
            <AddPlus size={20} color={(theme.textColor.grey as any)['300']} weight={2} />
          </li>
        )} */}
      <li>
        <div className="text-body-micro medium text-[var(--text-on-bg)] bg-[var(--backgrounds-default)] ml-3 p-1 rounded-md h-5 w-5 flex flex-col justify-center items-center">
          {selectedOptions.length}
        </div>
      </li>
    </ul>
  ) : (
    <div className="button ghost small justify-start w-fit transition !pl-1.5">
      <Plus />
      <span className="text-[var(--text-on-bg)] text-body-micro font-medium">
        Add collaborators
      </span>
    </div>
  );

  const noSearchResults =
    workspaceCollaborators.length === 0 && debouncedSearchTerm.length > 0;

  return (
    <Popover
      className={`relative ${collaboratorOptionsLength > 0 ? '' : '-ml-1'}`}
    >
      {({ open }) => (
        <Fragment>
          <Popover.Button className={`${open ? '' : ''} justify-start`}>
            {button}
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            {!readOnly ? (
              <Popover.Panel className="fixed top-32 md:top-auto w-[85%] md:translate-x-0 -translate-x-[8%] -translate-y-[8%] md:translate-y-0 md:absolute right-1 md:left-0 z-10 mt-2 md:w-[250px] space-y-4">
                <div className="max-h-[400px] overflow-y-auto drop-group p-1">
                  <div className="sticky top-0 z-10 bg-[var(--backgrounds-dropdowns)]">
                    {/* show this component when readonly is false */}
                    {readOnly || (
                      <div className="relative bg-[var(--backgrounds-dropdowns)]">
                        {/* Search Term Input */}
                        <input
                          autoFocus
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          id="collaborator-search"
                          type=""
                          className="input-clear !h-[34px] py-3 !pl-2 !pr-2 w-full text-[var(--text-default)] text-body-small regular placeholder:text-[var(--text-neutral)]"
                          placeholder="Add Collaborators"
                        />
                        {workspaceCollaborators.length > 1 && (
                          <HorizontalDivider />
                        )}
                      </div>
                    )}
                    {/* {noSearchResults || (
                    <div className="py-3 px-4 text-xs text-grey-300">
                      Collaborators
                    </div>
                  )} */}
                  </div>
                  <div className="">
                    {noSearchResults && (
                      <p className="text-center p-4 text-body-micro regular text-[var(--text-neutral)] whitespace-break-spaces">
                        No search found for “
                        <span className="font-semibold">
                          {debouncedSearchTerm}
                        </span>
                        ” in your workspace.
                      </p>
                    )}
                    <ul className="flex flex-col gap-1">
                      {!readOnly &&
                        workspaceCollaborators.map((user: IUser) => (
                          <li
                            key={user.id}
                            className={
                              Boolean(userId) && user.id === userId
                                ? 'hidden'
                                : ''
                            }
                          >
                            <label
                              className={classNames(
                                'flex items-center gap-2 px-2 rounded-lg h-11 hover:bg-[var(--backgrounds-hover-clicked)]',
                                { 'cursor-pointer': !readOnly }
                              )}
                            >
                              <Avatar
                                name={user.detail.fullName}
                                src={user.detail?.avatar?.url}
                                alt={user.detail.fullName}
                                size={28}
                              />
                              <div className="flex-1">
                                <div className="text-body-small medium text-[var(--text-default)]">
                                  {user.detail.fullName}
                                </div>
                                <div className="text-body-micro regular text-[var(--text-neutral)]">
                                  {transformToTitleCase(user.detail.position)}
                                </div>
                              </div>
                              {readOnly || (
                                <div className="">
                                  {/* {selectedOptions.includes(user) ?
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.793 1.33398H5.20634C2.77967 1.33398 1.33301 2.78065 1.33301 5.20732V10.7873C1.33301 13.2207 2.77967 14.6673 5.20634 14.6673H10.7863C13.213 14.6673 14.6597 13.2207 14.6597 10.794V5.20732C14.6663 2.78065 13.2197 1.33398 10.793 1.33398ZM11.1863 6.46732L7.40634 10.2473C7.31301 10.3407 7.18634 10.394 7.05301 10.394C6.91967 10.394 6.79301 10.3407 6.69967 10.2473L4.81301 8.36065C4.61967 8.16732 4.61967 7.84732 4.81301 7.65398C5.00634 7.46065 5.32634 7.46065 5.51967 7.65398L7.05301 9.18732L10.4797 5.76065C10.673 5.56732 10.993 5.56732 11.1863 5.76065C11.3797 5.95398 11.3797 6.26732 11.1863 6.46732Z" fill="#8178EF" />
                                </svg>
                                :
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="1.83301" y="1.83398" width="12.3333" height="12.3333" rx="2.16667" fill="none" stroke="var(--border-default)" />
                                </svg>
                              } */}

                                  {selectedOptions.includes(user) && (
                                    <div>
                                      <Check />
                                    </div>
                                  )}

                                  <input
                                    disabled={
                                      (Boolean(userId) && user.id === userId) ||
                                      readOnly
                                    }
                                    type="checkbox"
                                    className="sr-only input-clear border border-[var(--border-default)] !rounded bg-[var(--backgrounds-on-canvas)] checked:bg-[rgba(129, 120, 239, 1)]"
                                    value={user as any}
                                    checked={selectedOptions.includes(user)}
                                    onChange={() => handleCheckboxChange(user)}
                                  />
                                </div>
                              )}
                            </label>
                          </li>
                        ))}
                      {/* when its readonly, only show collaborators on the project */}
                      {readOnly &&
                        projectData?.project?.collaborators.map(
                          (user: IUser) => (
                            <li key={user.id}>
                              <label
                                className={classNames(
                                  'flex items-center gap-2 px-2 rounded-lg h-11 hover:bg-[var(--backgrounds-hover-clicked)]',
                                  { 'cursor-pointer': !readOnly }
                                )}
                              >
                                <Avatar
                                  name={user.detail.fullName}
                                  src={user.detail?.avatar?.url}
                                  alt={user.detail.fullName}
                                  size={28}
                                />
                                <div className="flex-1">
                                  <div className="text-body-small medium text-[var(--text-default)]">
                                    {user.detail.fullName}
                                  </div>
                                  <div className="text-body-micro regular text-[var(--text-neutral)]">
                                    {transformToTitleCase(user.detail.position)}
                                  </div>
                                </div>
                                {readOnly || (
                                  <div className="">
                                    {/* {selectedOptions.includes(user) ?
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path d="M10.793 1.33398H5.20634C2.77967 1.33398 1.33301 2.78065 1.33301 5.20732V10.7873C1.33301 13.2207 2.77967 14.6673 5.20634 14.6673H10.7863C13.213 14.6673 14.6597 13.2207 14.6597 10.794V5.20732C14.6663 2.78065 13.2197 1.33398 10.793 1.33398ZM11.1863 6.46732L7.40634 10.2473C7.31301 10.3407 7.18634 10.394 7.05301 10.394C6.91967 10.394 6.79301 10.3407 6.69967 10.2473L4.81301 8.36065C4.61967 8.16732 4.61967 7.84732 4.81301 7.65398C5.00634 7.46065 5.32634 7.46065 5.51967 7.65398L7.05301 9.18732L10.4797 5.76065C10.673 5.56732 10.993 5.56732 11.1863 5.76065C11.3797 5.95398 11.3797 6.26732 11.1863 6.46732Z" fill="#8178EF" />
                                </svg>
                                :
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="1.83301" y="1.83398" width="12.3333" height="12.3333" rx="2.16667" fill="none" stroke="var(--border-default)" />
                                </svg>
                              } */}

                                    {selectedOptions.includes(user) && (
                                      <div>
                                        <Check />
                                      </div>
                                    )}

                                    <input
                                      disabled={
                                        (Boolean(userId) &&
                                          user.id === userId) ||
                                        readOnly
                                      }
                                      type="checkbox"
                                      className={`sr-only input-clear border border-[var(--border-default)] !rounded bg-[var(--backgrounds-on-canvas)] checked:bg-[rgba(129, 120, 239, 1)]`}
                                      value={user as any}
                                      checked={selectedOptions.includes(user)}
                                      onChange={() =>
                                        handleCheckboxChange(user)
                                      }
                                    />
                                  </div>
                                )}
                              </label>
                            </li>
                          )
                        )}
                    </ul>
                  </div>
                </div>
              </Popover.Panel>
            ) : (
              <div />
            )}
          </Transition>
        </Fragment>
      )}
    </Popover>
  );
};

export default ProjectCollaboratorsSelect;
