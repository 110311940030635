import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useApolloOptions } from '../api';

import {
  CREATE_ONE_WORKSPACE_DOMAIN,
  DEACTIVATE_ONE_WORKSPACE_MEMBER,
  DELETE_ONE_WORKSPACE,
  DELETE_ONE_WORKSPACE_INVITATION,
  DISABLE_ONE_WORKSPACE_DOMAIN,
  ENABLE_ONE_WORKSPACE_DOMAIN,
  JOIN_ONE_WORKSPACE,
  REACTIVATE_ONE_WORKSPACE_MEMBER,
  REMOVE_ONE_WORKSPACE_DOMAIN,
  SEND_ONE_WORKSPACE_DOMAIN_TOKEN,
  UPDATE_ONE_WORKSPACE,
  UPDATE_ONE_WORKSPACE_INVITATION,
  UPDATE_ONE_WORKSPACE_LOGO,
  UPDATE_ONE_WORKSPACE_MEMBER_ROLE,
  VERIFY_DELETE_ONE_WORKSPACE,
  VERIFY_ONE_WORKSPACE_DOMAIN,
} from 'src/lib/mutations';
import {
  ME,
  WORKSPACE,
  WORKSPACES,
  WORKSPACE_BY_INVITATION,
} from 'src/lib/queries';

export function useWorkspaces() {
  const options = useApolloOptions();

  return useQuery(WORKSPACES, options);
}

export function useWorkspace(args: object) {
  const options = useApolloOptions();
  return useQuery(WORKSPACE, { ...options, ...args });
}

export function useLazyWorkspace() {
  const options = useApolloOptions();

  return useLazyQuery(WORKSPACE, options);
}

export function useVerifyOneWorkspaceDomain() {
  const options = useApolloOptions();

  return useMutation(VERIFY_ONE_WORKSPACE_DOMAIN, { ...options });
}

export function useVerifyDeleteOneWorkspace() {
  const options = useApolloOptions();

  return useMutation(VERIFY_DELETE_ONE_WORKSPACE, { ...options });
}

export function useUpdateOneWorkspaceMemberRole() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_WORKSPACE_MEMBER_ROLE, { ...options });
}

export function useUpdateOneWorkspaceLogo() {
  const options = useApolloOptions();
  return useMutation(UPDATE_ONE_WORKSPACE_LOGO, {
    ...options,
    refetchQueries: [{ query: ME, ...options }],
  });
}

export function useUpdateOneWorkspaceInvitation() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_WORKSPACE_INVITATION, { ...options });
}

export function useUpdateOneWorkspace() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_WORKSPACE, { ...options });
}

export function useSendOneWorkspaceDomainToken() {
  const options = useApolloOptions();
  return useMutation(SEND_ONE_WORKSPACE_DOMAIN_TOKEN, { ...options });
}

export function useRemoveOneWorkspaceDomain() {
  const options = useApolloOptions();

  return useMutation(REMOVE_ONE_WORKSPACE_DOMAIN, { ...options });
}

export function useReactivateOneWorkspaceMember() {
  const options = useApolloOptions();

  return useMutation(REACTIVATE_ONE_WORKSPACE_MEMBER, { ...options });
}

export function useWorkSpaceByInvitation(args: object) {
  const options = useApolloOptions();
  return useQuery(WORKSPACE_BY_INVITATION, { ...options, ...args });
}

export function useJoinOneWorkspace() {
  const options = useApolloOptions();

  return useMutation(JOIN_ONE_WORKSPACE, {
    ...options,
    refetchQueries: [{ query: ME, ...options }],
  });
}

export function useEnableOneWorkspaceDomain() {
  const options = useApolloOptions();

  return useMutation(ENABLE_ONE_WORKSPACE_DOMAIN, { ...options });
}

export function useDisableOneWorkspaceDomain() {
  const options = useApolloOptions();

  return useMutation(DISABLE_ONE_WORKSPACE_DOMAIN, { ...options });
}

export function useDeleteOneWorkspaceInvitation() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_WORKSPACE_INVITATION, options);
}

export function useDeleteOneWorkspace() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_WORKSPACE, { ...options });
}

export function useDeactivateOneWorkspaceMember() {
  const options = useApolloOptions();

  return useMutation(DEACTIVATE_ONE_WORKSPACE_MEMBER, { ...options });
}

export function useCreateOneWorkspaceDomain() {
  const options = useApolloOptions();

  return useMutation(CREATE_ONE_WORKSPACE_DOMAIN, { ...options });
}
