import { useLocalStorage } from '@uidotdev/usehooks';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import Validator from 'validatorjs';
// import Button from "src/components/Button";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build';
import { AnimatePresence, motion } from 'framer-motion';
import { toast } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useCreateOneProject } from 'src/api/project/useCreateOneProject';
import { useUpdateOneProject } from 'src/api/project/useUpdateOneProject';
import { Close } from 'src/assets/icons';
import EditProjectDetails from 'src/components/EditProjectDetails';
import ValidationErrorMessages from 'src/components/ValidationErrorMessages';
import usePrevious from 'src/hooks/usePrevious';
import { deepEqual, deleteTypeNameKey } from 'src/lib/helpers';
import { IProject, IProjectLink, ProjectStatus } from 'src/lib/types';
import ToastMessage from './ToastMessage';
import Button from './ui/Button/Button';
import IconButton from './ui/Button/IconButton';
import HeaderText from './ui/HeaderTexts';
import HorizontalDivider from './ui/HorizontalDivider';

type FormData = {
  name: string;
  description: string;
  owner: string;
  collaboratorIDs: string[];
  topicIDs: string[];
  // goalIDs: string[];
  status: ProjectStatus;
  isPublic: boolean;
  links: IProjectLink[];
};

export default function EditProjectModal({
  show,
  handleClose,
  project,
}: {
  show: boolean;
  // projectLead: {
  //   name: string;
  //   src: string;
  //   alt: string;
  //   position: string;
  // };
  handleClose: Function;
  project?: IProject;
}) {
  const navigate = useNavigate();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [userId] = useLocalStorage('userId', undefined);
  const [formData, setFormData] = useState<FormData>({
    name: project?.name ?? '',
    description: project?.description ?? '',
    owner: project?.owner.id ?? userId ?? '',
    collaboratorIDs: project?.collaboratorIDs ?? [],
    topicIDs: project?.topicIDs ?? [],
    // goalIDs: project?.goalIDs ?? [],
    status: project?.status ?? ProjectStatus.PLANNING,
    isPublic: project?.isPublic ?? true,
    links: project?.links.map(deleteTypeNameKey) ?? [],
  });

  const prevProject = usePrevious(project);
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);
  const [createOneProject, { loading: createOneProjectLoading }] =
    useCreateOneProject();
  const [updateOneProject, { loading: updateOneProjectLoading }] =
    useUpdateOneProject();
  const loading = createOneProjectLoading || updateOneProjectLoading;

  useEffect(() => {
    if (prevProject && project && !deepEqual(prevProject, project)) {
      setFormData({
        name: project.name,
        description: project.description,
        owner: project.owner.id,
        collaboratorIDs: project.collaboratorIDs,
        topicIDs: project.topicIDs,
        // goalIDs: project.goalIDs,
        status: project.status,
        isPublic: project.isPublic,
        links: project.links.map(deleteTypeNameKey),
      });
    }
  }, [prevProject, project]);

  const handleInputChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;

      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      setFormData({ ...formData, [field]: value });
      setFormErrors(null);
    };

  const handleSubmit = () => {
    const validation = new Validator(formData, {
      name: 'required',
      description: 'required',
    });

    if (validation.passes()) {
      setFormErrors(null);

      if (project) {
        updateOneProject({
          variables: {
            data: {
              owner: {
                connect: { id: formData.owner },
              },
              collaborators: {
                set: formData.collaboratorIDs.map((id) => ({ id })),
              },
              // goals: {
              //   set: formData.goalIDs.map((id) => ({ id })),
              // },
              topics: {
                set: formData.topicIDs.map((id) => ({ id })),
              },
              description: { set: formData.description },
              isPublic: { set: formData.isPublic },
              name: { set: formData.name },
              status: { set: formData.status },
              links: {
                upsert: formData.links.map((link) => ({
                  create: link,
                  update: {
                    label: { set: link.label },
                    url: { set: link.url },
                  },
                  where: {
                    url_projectId: {
                      projectId: project.id,
                      url: link.url,
                    },
                  },
                })),
              },
            },
            where: { id: project.id },
          },
          onCompleted: () => handleClose(),
          onError: (error) => {
            toast((t) => (
              <ToastMessage id={t.id} title={error.message}>
                {error.message}
              </ToastMessage>
            ));
          },
        });
      } else {
        createOneProject({
          variables: {
            data: {
              owner: { connect: { id: formData.owner } },
              collaborators: {
                connect: formData.collaboratorIDs.map((id) => ({ id })),
              },
              // goals: { connect: formData.goalIDs.map((id) => ({ id })) },
              topics: { connect: formData.topicIDs.map((id) => ({ id })) },
              description: formData.description,
              isPublic: formData.isPublic,
              name: formData.name,
              status: formData.status,
              workspace: { connect: { slug: workspaceSlug } },
              links: formData.links.length
                ? {
                    createMany: { data: formData.links },
                  }
                : undefined,
            },
          },
          onCompleted: ({ createOneProject }) => {
            navigate(
              `/workspace/${workspaceSlug}/project/${createOneProject.id}`
            );
            handleClose();
          },
          onError: (error) => {
            toast((t) => (
              <ToastMessage id={t.id} title={error.message}>
                {error.message}
              </ToastMessage>
            ));
          },
        });
      }
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  const title = project ? 'Edit Project' : 'Create a project';
  const button = project ? 'Save' : 'Create project';

  return (
    <Fragment>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed z-20 inset-0 bg-black/30"
          >
            <div className="fixed inset-0 flex w-screen items-start justify-center p-4 overflow-auto">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="group-container border mt-8 md:mt-16 rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[528px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]"
              >
                <div className="flex items-center justify-between w-full p-5">
                  <HeaderText subTitle={title} />
                  <IconButton
                    icon={<Close />}
                    size={'micro'}
                    type={'ghost'}
                    onClick={() => handleClose()}
                  />
                </div>
                <HorizontalDivider />
                <div className="p-5 flex flex-col gap-8">
                  <div className="">
                    <input
                      className="w-full input-clear text-heading-4 medium placeholder:text-[var(--text-disabled)] placeholder:text-heading-4 text-[var(--text-default)]"
                      placeholder="Project name"
                      disabled={loading}
                      value={formData.name}
                      autoFocus
                      onChange={handleInputChange('name')}
                    />
                    <ValidationErrorMessages name="name" errors={formErrors} />

                    <div className="CKEditor">
                      <CKEditor
                        disabled={loading}
                        editor={BalloonEditor as any}
                        data={formData.description}
                        onChange={(event, editor) =>
                          handleInputChange('description')(editor.getData())
                        }
                        config={{ placeholder: 'Description' }}
                      />
                      <ValidationErrorMessages
                        name="description"
                        errors={formErrors}
                      />
                    </div>
                  </div>
                  {workspaceSlug && (
                    <EditProjectDetails
                      // user={projectLead}
                      workspaceSlug={workspaceSlug}
                      value={formData}
                      onChange={handleInputChange}
                      readOnly={false}
                    />
                  )}
                </div>
                <HorizontalDivider />
                <div className="p-5">
                  <div className="flex justify-end items-center">
                    <div className="items-center flex gap-3 justify-end">
                      <Button
                        type="button"
                        onClick={() => handleClose()}
                        disabled={loading}
                        value={'Cancel'}
                        size={'medium'}
                        btnType={'neutral'}
                        className="w-auto"
                      />
                      <Button
                        type="button"
                        onClick={handleSubmit}
                        loading={loading}
                        disabled={loading}
                        value={button}
                        size={'medium'}
                        btnType={'primary'}
                        className="w-auto"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
}
