import { Network } from 'src/assets/icons';
import { IProjectLink } from 'src/lib/types';

function getLinkLogoImage(url: string) {
  if (url.includes('figma.com')) return 'figma-logo.png';
  if (url.includes('linear.app')) return 'linear-logo.png';
  if (url.includes('jira.com')) return 'jira-logo.png';
  if (url.includes('confluence.com')) return 'confluence-logo.png';
  if (url.includes('notion.so')) return 'notion-logo.png';
  if (url.includes('docs.google')) return 'google-docs-logo.png';
}

function LinkLogo(props: { link: IProjectLink }) {
  const { link } = props;
  const logoImage = getLinkLogoImage(link.url);

  if (logoImage) {
    return (
      <img
        src={`/images/links/${logoImage}`}
        alt={link.label}
        className="w-4 aspect-square rounded object-cover"
      />
    );
  }

  return (
    <div className="w-4 aspect-square rounded  grid place-content-center">
      <Network />
    </div>
  );
}

export default LinkLogo;
