import { useNavigate } from 'react-router-dom';
import { ChevronDown } from 'src/assets/icons';
import Button from './Button';

export default function BackButton() {
  const navigate = useNavigate();

  return (
    <>
      <Button
        btnType="ghost"
        size="medium"
        value="Back"
        icon="left"
        className="w-auto"
        onClick={() => {
          navigate(-1);
        }}
      >
        <ChevronDown className=" rotate-90 w-4 h-4" />
      </Button>
    </>
  );
}
