import clsx from 'clsx';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { ChatBubble, Inbox, MegaPhone, Projects } from 'src/assets/icons';

function SideNavMobile() {
  // route definitions
  const { workspaceSlug } = useParams();
  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}`;

  const NavItem = [
    {
      name: 'Project',
      icon: <Projects className="w-4 h-4" />,
      link: `${WORKSPACE_URL_BASE}/project`,
    },
    {
      name: 'Feed',
      icon: <MegaPhone className="w-4 h-4" />,
      link: `${WORKSPACE_URL_BASE}/feeds`,
    },
    {
      name: 'Chat',
      icon: <ChatBubble className="w-4 h-4" />,
      link: `${WORKSPACE_URL_BASE}/chat`,
    },
    {
      name: 'Inbox',
      icon: <Inbox className="w-4 h-4" />,
      link: `${WORKSPACE_URL_BASE}/inbox`,
    },
  ];

  return (
    <div className="fixed left-0 bottom-0 z-0 flex w-screen justify-between py-4 px-5 border-y border-y-[var(--border-default)] bg-[var(--backgrounds-on-canvas)] md:hidden">
      {NavItem.map((item) => (
        <SideNavMobileBtn
          key={item.link}
          link={item.link}
          icon={item.icon}
          name={item.name}
        />
      ))}
    </div>
  );
}

function SideNavMobileBtn({
  link,
  icon,
  name,
}: {
  link: string;
  icon: React.ReactNode;
  name: string;
}) {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Link to={link}>
      <div
        className={clsx('flex flex-col gap-2 items-center SideNavMobileBtn', {
          active: pathname.includes(link || ''),
          'exact-active':
            pathname === link || (name === 'Project' && link + '/all'),
        })}
      >
        <div>{icon}</div>
        <p className="text-body-micro medium">{name}</p>
      </div>
    </Link>
  );
}

export default SideNavMobile;
