import { useMutation } from '@apollo/client';
import { FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import ToastMessage from 'src/components/ToastMessage';
import BackButton from 'src/components/ui/Button/BackButton';
import Button from 'src/components/ui/Button/Button';
import Input from 'src/components/ui/inputs/Default';
import { useToast } from 'src/context/ToastContext';
import { validateEmail } from 'src/lib/utils';
import { RESET_PASSWORD } from '../../lib/mutations';

type FormData = {
  email: string;
};

function ResetPassword() {
  // graphQL definitions
  const { showErrorToast } = useToast();
  const [resetPassword, { data, loading, error }] = useMutation(RESET_PASSWORD);
  const errorMessage = error && `${error.message}`;

  // react state and router
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  async function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (validateEmail(email)) {
      setEmailError('');

      resetPassword({
        variables: { email },
        onCompleted: ({ resetPassword }) => {
          navigate('/auth/verify-otp?email=' + email + '&ui=basic');
        },
        onError: (error: any) => {
          toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
        },
      });
    } else {
      setEmailError('Invalid email format.');
    }
  }

  return (
    <>
      <div className="w-full flex flex-col gap-4 items-start">
        <BackButton />
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3 bold text-[var(--text-default)]">
            Reset password
          </h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            Enter the email address that you used to create your account.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit} className="w-full flex flex-col gap-6">
        <Input
          autoFocus
          type="email"
          value={email}
          feedback={emailError}
          feedbackType={emailError.length ? 'error' : 'success'}
          label="Email address"
          placeholder="Enter your email address"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          btnType="primary"
          size="large"
          value="Continue"
          loading={loading}
          disabled={loading || !email}
          className="w-full"
        />
      </form>
    </>
  );
}

export default ResetPassword;
