// import Modal from "src/components/Modal";
// import Button from "src/components/Button";
import { TrashDeleteBin } from 'react-basicons';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useArchiveOneProject } from 'src/api/project/useArchiveOneProject';
import { useDeleteOneProject } from 'src/api/project/useDeleteOneProject';
import ToastMessage from 'src/components/ToastMessage';
import { IProject } from 'src/lib/types';

import { AnimatePresence, motion } from 'framer-motion';
import { Archive, Trash } from 'src/assets/icons';
import Button from './ui/Button/Button';
import Modal from './ui/Modals/Modal';

export default function DeleteProject(props: {
  show: boolean;
  handleClose: Function;
  handlePreArchive: Function;
  handlePreDelete: Function;
  project: IProject;
}) {
  const { show, handleClose, handlePreArchive, handlePreDelete, project } =
    props;
  const navigate = useNavigate();
  const [archiveOneProject, { loading: archiveOneProjectLoading }] =
    useArchiveOneProject();
  const [deleteOneProject, { loading: deleteOneProjectLoading }] =
    useDeleteOneProject();

  // function handlePreArchive() {
  //   const projectData = {
  //     id: project.id
  //   }
  //   navigate(`/workspace/${project.workspace.slug}`, { replace: true });
  //   console.log(projectData)
  // }

  const handleArchive = () => {
    archiveOneProject({
      variables: {
        where: { id: project.id },
      },
      onCompleted: (data) => {
        navigate(`/workspace/${project.workspace.slug}`, { replace: true });
        toast((t) => (
          <ToastMessage
            id={t.id}
            icon={<TrashDeleteBin size={16} weight={3} />}
            title="This project has been archived."
            handleUndo={() => console.log('undoing...')}
          >
            You can go to the “project” part of workspace settings to access
            this project
          </ToastMessage>
        ));
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            icon={<TrashDeleteBin size={16} weight={3} />}
            title={'There was an error archiving this project.'}
          >
            {error.message}
          </ToastMessage>
        ));
      },
    });
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center"
        >
          <Modal
            open={show}
            data={{
              subtitle: 'You’re about to delete this project',
              snippet:
                'If you change your mind, you can restore it within the next 30 days. After that it will be permanently deleted from your workspace.',
              btn1: 'Cancel',
              btn2: 'Delete',
              btn1Type: 'neutral',
              btn2Type: 'destructive',
            }}
            showCTA={false}
          >
            <div className="flex items-center justify-between">
              <Button
                className={'w-auto'}
                btnType="neutral"
                size="medium"
                icon="left"
                value={'Archive instead'}
                onClick={() => {
                  handlePreArchive();
                  handleClose();
                }}
              >
                <Archive />
              </Button>
              <div className="flex justify-end gap-3 items-center">
                <Button
                  className={'w-auto'}
                  btnType="ghost"
                  size="medium"
                  value={'Cancel'}
                  onClick={() => handleClose()}
                />
                <Button
                  className={'w-auto'}
                  btnType="destructive"
                  size="medium"
                  icon="left"
                  value={'Delete'}
                  onClick={() => {
                    handlePreDelete();
                    handleClose();
                  }}
                >
                  <Trash />
                </Button>
              </div>
            </div>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
