'use client';

import { useEffect, useRef, useState } from 'react';
import { IPostComment } from 'src/lib/types';
// import Avatar from "src/components/Avatar";
import { useLocalStorage } from '@uidotdev/usehooks';
import TextareaAutosize from 'react-textarea-autosize';
import PostCommentMenu from 'src/components/PostCommentMenu';
// import Button from "src/components/Button";
import { AnimatePresence, motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import { useUpdateOnePost } from 'src/api/post/useUpdateOnePost';
import { ChevronDown } from 'src/assets/icons';
import DeletePostComment from 'src/components/DeletePostComment';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from './ui/Avatar/Avatar';
import Button from './ui/Button/Button';
import IconButton from './ui/Button/IconButton';
import Timestamp from './ui/TimeStamp';

function PostCommentBody(props: { comment: IPostComment }) {
  const { comment } = props;
  const [userId] = useLocalStorage('userId', undefined);
  const isCommentOwner = userId === comment.author.id;
  const [editComment, setEditComment] = useState<string | undefined>(undefined);
  const [deleteComment, setDeleteComment] = useState<IPostComment | null>(null);
  const [updateOnePost, { loading: updateCommentLoading }] = useUpdateOnePost();

  const [searchParams] = useSearchParams();
  const commentId = searchParams.get('comment');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const isFocusedComment = commentId === comment.id;

  useEffect(() => {
    if (isFocusedComment && componentRef.current instanceof HTMLElement) {
      const element = componentRef.current;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      element.classList.add('bg-warning-50');
      setTimeout(() => {
        element.classList.remove('bg-warning-50');
      }, 2000);
    }
  }, [isFocusedComment]);

  const handleCopy = () => {
    const origin = window.location.origin;
    const post = comment.post;
    const project = post.project;
    const workspace = project.workspace;
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}?post=${post.id}&comment=${comment.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            // visible={t.visible}
            title={'Link to comment is copied to clipboard.'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            // visible={t.visible}
            title={'Error copying text to clipboard.'}
          />
        ));
      });
  };

  const handleEdit = () => setEditComment(comment.comment);

  const handleDelete = () => setDeleteComment(comment);

  const handleSave = () => {
    updateOnePost({
      variables: {
        data: {
          comments: {
            update: [
              {
                data: {
                  comment: { set: editComment },
                },
                where: { id: comment.id },
              },
            ],
          },
        },
        where: { id: comment.post.id },
      },
      onCompleted: () => {
        setEditComment(undefined);
      },
    });
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      // Measure the height of 6 lines
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );
      // console.log(parseFloat(getComputedStyle(contentRef.current).lineHeight), 'parseFloat(getComputedStyle(contentRef.current).lineHeight)')
      const maxHeight = lineHeight * 6;

      // Check if content height exceeds the height of 6 lines
      // console.log(contentRef.current.scrollHeight, 'contentRef.current.scrollHeight')
      if (contentRef.current.scrollHeight > maxHeight) {
        setIsTruncated(false);
        // setIsTruncated(true);
      }
    }
  }, [comment]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setIsHovered(!isHovered);
  };

  return (
    <div ref={componentRef} className="duration-1000 flex flex-col gap-4">
      <div className="flex items-center space-x-6">
        <div className="flex-1 flex space-x-2 items-center">
          <Avatar
            alt={comment.author.detail.fullName}
            src={comment.author.detail.avatar?.url}
            size={28}
            name={comment.author.detail.fullName}
          />
          <div className="">
            <div className="text-body-small text-[var(--text-default)] font-medium">
              {comment.author.detail.fullName}
            </div>
            <div className="text-body-micro text-[var(--text-neutral)] regular">
              {/* {formatDistance(parseISO(comment.createdAt), new Date(), {
                addSuffix: true,
              })} */}
              {/* {formatRelativeTime(comment.createdAt)} */}
              <Timestamp createdAt={comment.createdAt} />
            </div>
          </div>
        </div>

        <PostCommentMenu
          canEdit={isCommentOwner}
          canDelete={isCommentOwner}
          canArchive={isCommentOwner}
          handleCopy={handleCopy}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          type={'comment'}
        />
      </div>

      {Boolean(editComment) || (
        <div className="w-full relative">
          <p
            ref={contentRef}
            className={`text-body-small regular text-[var(--text-default)] ${
              isExpanded ? '' : 'article truncate-lines-'
            }`}
          >
            {comment.comment}
          </p>
          {isTruncated && (
            <div
              className="absolute bottom-0 h-[72px] w-full flex flex-col items-center justify-end"
              style={{
                background:
                  !isExpanded || isHovered ? 'var(--truncate-bg)' : 'none',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <AnimatePresence>
                {isExpanded && isHovered && (
                  <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <IconButton
                      icon={<ChevronDown className="rotate-180" />}
                      size={'standard'}
                      type={'neutral'}
                      rounded="full"
                      onClick={toggleExpand}
                    />
                  </motion.div>
                )}

                {!isExpanded && (
                  <IconButton
                    icon={<ChevronDown />}
                    size={'standard'}
                    type={'neutral'}
                    onClick={toggleExpand}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      )}

      {Boolean(editComment) && (
        <div className="space-y-4">
          <TextareaAutosize
            autoFocus
            value={editComment}
            onChange={(e) => setEditComment(e.target.value)}
            placeholder="Edit this comment"
            disabled={updateCommentLoading}
            className="resize-none w-full text-sm input"
          />

          <div className="flex items-center justify-end space-x-3">
            <Button
              onClick={() => setEditComment(undefined)}
              type="button"
              size="small"
              className={'w-fit'}
              value={'Cancel'}
              btnType={'neutral'}
            />
            <Button
              onClick={handleSave}
              type="button"
              size="small"
              className={'w-fit'}
              loading={updateCommentLoading}
              disabled={updateCommentLoading || editComment === comment.comment}
              value={'Saveeee'}
              btnType={'primary'}
            />
          </div>
        </div>
      )}

      <DeletePostComment
        comment={deleteComment}
        show={Boolean(deleteComment)}
        handleClose={() => setDeleteComment(null)}
      />
    </div>
  );
}

export default PostCommentBody;
