import React from 'react';
import { Triangle } from 'src/assets/icons';
import DropGroup from '../DropGroup';
import '../ui.css';

interface InputProps {
  data: {
    name: string;
    value: string;
    type?: string;
    icon?: React.ReactNode;
    snippet?: string;
  }[];
  // label?: string;
  placeholder?: string;
  value?: string;
  // feedback: string;
  search?: boolean;
  showDropGroup: boolean;
  title?: string;
  className?: string;
  style?: React.CSSProperties;
  onItemClick: (item: any) => void;
  clickOutside?: () => void;
  onClick: () => any;
}

const SelectInput: React.FC<InputProps> = ({
  value,
  data,
  search,
  placeholder,
  title,
  className,
  style,
  showDropGroup,
  onItemClick,
  clickOutside,
  onClick,
}) => {
  return (
    <div className="link-input relative w-full">
      <div
        className={`text-body-small regular text-[var(--text-default)] flex cursor-pointer rounded-lg select_container px-4 items-center h-10
                ${showDropGroup && 'focus'}`}
        onClick={onClick}
      >
        {value ? (
          value
        ) : (
          <span className="text-[var(--text-disabled)]">{placeholder}</span>
        )}
        <div className="absolute top-1/2 right-4 transform -translate-x-1/2 -translate-y-1/2 flex items-center">
          <Triangle />
        </div>
      </div>
      <div className="absolute z-10 top-[calc(100%_+_8px)] w-full">
        <DropGroup
          open={showDropGroup}
          data={data}
          search={search}
          title={title}
          className={className}
          style={style}
          onClick={onItemClick}
          clickOutside={clickOutside}
        />
      </div>
    </div>
  );
};

export default SelectInput;
