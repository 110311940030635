import { useDebounce, useLocalStorage } from '@uidotdev/usehooks';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { useSingleUpload } from '../api/useSingleUpload';
import usePrevious from '../hooks/usePrevious';
// import { useCreateOneUserWorkspace } from '../api/useCreateOneUserWorkspace';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { useCreateOneUserWorkspace } from 'src/api/workspace/useCreateOneUserWorkspace';
import Validator from 'validatorjs';
import { useLazyWorkspace } from '../api/workspace/useWorkspace';
import UrlInput from '../components/UrlInput';
import ValidationErrorMessages from '../components/ValidationErrorMessages';
// import Button from "../components/Button";
import toast from 'react-hot-toast';
import { Plus } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/ui/Button/Button';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import { MAX_FILE_UPLOAD_SIZE } from 'src/lib/consts';

type FormData = {
  logo?: File;
  name: string;
  slug: string;
  website: string;
};

function CreateWorkspacePage() {
  const navigate = useNavigate();
  const [userId] = useLocalStorage('userId', undefined);

  const [singleUpload] = useSingleUpload();
  const [imagePreview, setImagePreview] = useState<string | ArrayBuffer | null>(
    null
  );
  const [formData, setFormData] = useState<FormData>({
    name: '',
    slug: '',
    website: '',
  });
  const debouncedFormData = useDebounce(formData, 300);
  const prevFormData = usePrevious<FormData>(formData);
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);
  const [createOneUserWorkspace, { data, loading, error }] =
    useCreateOneUserWorkspace();
  const errorMessage = error && `Submission error! ${error.message}`;
  const disabled = loading;
  const [
    workspaceSlug,
    {
      data: workspaceSlugData,
      loading: workspaceSlugLoading,
      error: workspaceSlugError,
    },
  ] = useLazyWorkspace();

  // useEffect(() => {
  //   if (formData.name !== prevFormData?.name) {
  //     setFormData({
  //       ...formData,
  //       slug: formData.name.replaceAll(' ', '-').toLowerCase(),
  //     });
  //   }

  //   if (formData.slug !== prevFormData?.slug) {
  //     setFormData({
  //       ...formData,
  //       slug: formData.slug.replaceAll(' ', '-').toLowerCase(),
  //     });
  //   }
  // }, [formData, prevFormData]);

  useEffect(() => {
    if (debouncedFormData.slug) {
      workspaceSlug({
        variables: { where: { slug: debouncedFormData.slug } },
        fetchPolicy: 'network-only',
      });
    }
  }, [debouncedFormData.slug, workspaceSlug]);

  const handleFileButton = () => {
    const fileInput = document.getElementById('workspace-logo');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    let file = e.target.files?.[0];

    if (file) {
      if (file.size > MAX_FILE_UPLOAD_SIZE.bytes) {
        toast.dismiss(); // Dismiss any previous toasts before showing a new one
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`The image you’re trying to upload is too large. Files should be less than ${MAX_FILE_UPLOAD_SIZE.humanReadable}.`}
          />
        ));
      } else {
        const reader = new FileReader();

        reader.onloadend = () => {
          setImagePreview(reader.result);
        };

        reader.readAsDataURL(file);

        setFormData({ ...formData, logo: file });
      }
    }
  };

  const handleInputChange =
    (field: string) => (e: ChangeEvent<HTMLInputElement> | any) => {
      let value;

      try {
        value = e.target.value;
      } catch (error) {
        value = e;
      }

      setFormData({ ...formData, [field]: value });
      setFormErrors(null);
    };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(formData, {
      name: 'required',
      slug: 'required',
      website: 'required|url',
    });

    if (validation.passes()) {
      setFormErrors(null);

      let singleUploadResult;

      if (formData.logo) {
        const { data: result } = await singleUpload({
          variables: { file: formData.logo },
        });
        singleUploadResult = result.singleUpload;
      }

      createOneUserWorkspace({
        onCompleted: ({ createOneUserWorkspace }) => {
          navigate(
            '/invite-to-workspace?workspace=' +
              createOneUserWorkspace.workspace.slug
          );
        },
        variables: {
          data: {
            user: {
              connect: { id: userId },
            },
            workspace: {
              create: {
                logo: singleUploadResult
                  ? {
                      set: {
                        filename: singleUploadResult.filename,
                        mimetype: singleUploadResult.mimetype,
                        url: singleUploadResult.url,
                      },
                    }
                  : undefined,
                name: formData.name,
                slug: formData.slug,
                website: formData.website,
              },
            },
          },
        },
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md pb-4">
      <div className="flex flex-col gap-10 md:gap-14">
        <div className="space-y-2">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
            Create workspace
          </h3>
        </div>

        <div className="flex flex-col gap-8 md:gap-10">
          <div className="flex flex-col gap-3 items-start w-full">
            <label
              className="text-body-small regular text-[var(--text-default)]"
              htmlFor="workspace-logo"
            >
              <HeaderText subTitle="Logo" />
            </label>
            <input
              id="workspace-logo"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                handleImageChange(e);
                e.target.value = ''; // This resets the input
              }}
              className="sr-only"
              accept="image/*"
              type="file"
            />
            <button
              type="button"
              onClick={handleFileButton}
              style={{
                backgroundImage: imagePreview
                  ? `url(${imagePreview as string})`
                  : '',
              }}
              className={classNames(
                'aspect-square relative bg-center rounded-lg bg-cover bg-no-repeat w-10 h-10',
                imagePreview ? ' group relative' : 'neutral'
              )}
            >
              <span
                className={classNames(
                  'absolute inset-0 grid place-content-center',
                  {
                    'opacity-0 group-hover:opacity-100 group-hover:bg-black/75':
                      imagePreview,
                  }
                )}
              >
                <Plus />
              </span>
            </button>
            <p className="text-body-micro text-[var(--text-neutral)]">
              Upload your workspace logo, you can always change or add it later.
            </p>
          </div>

          <HorizontalDivider />

          <div className="flex flex-col gap-6">
            <HeaderText subTitle="Workspace details" />

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2 items-start w-full">
                <label
                  className="text-body-small regular text-[var(--text-default)]"
                  htmlFor="workspace-name"
                >
                  Workspace name
                </label>
                <input
                  id="workspace-name"
                  placeholder="Example Inc."
                  className="w-full input"
                  value={formData.name}
                  onChange={handleInputChange('name')}
                />
                <ValidationErrorMessages name="name" errors={formErrors} />
              </div>

              <div className="flex flex-col gap-2 items-start w-full">
                <label
                  className="text-body-small regular text-[var(--text-default)]"
                  htmlFor="workspace-url"
                >
                  Workspace URL
                </label>
                <UrlInput
                  id="workspace-url"
                  inputTypeW_url
                  value={formData.slug}
                  onChange={handleInputChange('slug')}
                  domain="overlap.work"
                  formatted={false}
                  loading={workspaceSlugLoading}
                  success={Boolean(workspaceSlugError)}
                  error={Boolean(workspaceSlugData)}
                />
                <ValidationErrorMessages name="slug" errors={formErrors} />
              </div>

              <div className="flex flex-col gap-2 items-start w-full">
                <label
                  className="text-body-small regular text-[var(--text-default)]"
                  htmlFor="workspace-website"
                >
                  Website
                </label>
                <UrlInput
                  id="workspace-website"
                  value={formData.website}
                  onChange={handleInputChange('website')}
                  placeholder="www.example.com"
                />
                <ValidationErrorMessages name="website" errors={formErrors} />
              </div>
            </div>

            {errorMessage && (
              <p className="text-xs text-red-500 text-center">{errorMessage}</p>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={disabled}
              loading={loading}
              value={'Continue'}
              size={'large'}
              btnType={'primary'}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default CreateWorkspacePage;
