import { useLocalStorage } from '@uidotdev/usehooks';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { useMe } from 'src/api/auth/useMe';
import WorkspaceMenu from 'src/components/WorkspaceMenu';
import HomeSideNav from 'src/components/sideNav/HomeSideNav';
import SideNav from 'src/components/sideNav/SideNav';
import { PostsContextProvider } from 'src/context/PostContext';
import { ProjectContextProvider } from 'src/context/ProjectContext';
import { IUserWorkspace } from 'src/lib/types';

export default function DashboardLayout() {
  const { data } = useMe();

  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const [accessToken] = useLocalStorage('accessToken', undefined);
  const userWorkspaces = data?.me?.userWorkspaces;
  const userWorkspace = userWorkspaces?.find(
    ({ workspace }: IUserWorkspace) => workspace.slug === workspaceSlug
  );

  return !accessToken ? (
    <Navigate to="/auth/login" />
  ) : (
    <ProjectContextProvider>
      <PostsContextProvider>
        <div className="flex fixed min-h-screen w-full">
          <span className="min-h-screen hidden md:block">
            <SideNav>
              {data?.me && (
                <WorkspaceMenu
                  userWorkspace={userWorkspace}
                  userWorkspaces={userWorkspaces}
                  user={data.me}
                  // onChange={workspace => redirect(`/workspace/${workspace.slug}`)}
                />
              )}
              <HomeSideNav />
            </SideNav>
          </span>
          <div className="w-full h-screen md:h-[calc(100vh-16px)] m-0 overflow-auto flex gap-2 md:m-2">
            <Outlet />
          </div>
        </div>
      </PostsContextProvider>
    </ProjectContextProvider>
  );
}
