import { useLocalStorage } from '@uidotdev/usehooks';

export function useApolloOptions() {
  const [accessToken] = useLocalStorage('accessToken', undefined);

  return accessToken
    ? {
        context: {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Apollo-Require-Preflight': 'true',
          },
        },
      }
    : {};
}
