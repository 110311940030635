import { useLocalStorage } from '@uidotdev/usehooks';
import { redirect } from 'react-router';

export function useLogout() {
  const [userId, setUserId] = useLocalStorage('userId', undefined);
  const [accessToken, setAccessToken] = useLocalStorage(
    'accessToken',
    undefined
  );

  return () => {
    if (userId) {
      setUserId(undefined);
    }

    if (accessToken) {
      setAccessToken(undefined);
    }

    redirect('/auth/login');
  };
}
