import { useMutation } from '@apollo/client';
import { useApolloOptions } from '../api';
import { ME } from 'src/lib/queries';
import { JOIN_ONE_WORKSPACE } from 'src/lib/mutations';

export function useJoinOneWorkspace() {
  const options = useApolloOptions();

  return useMutation(JOIN_ONE_WORKSPACE, {
    ...options,
    refetchQueries: [{ query: ME, ...options }],
  });
}
