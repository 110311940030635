import React from 'react';

// Define the props interface
interface AssignItemProps {
    icon: React.ReactNode;
    text: string;
}


const AssignItem: React.FC<AssignItemProps> = ({ icon, text }) => {
    return (
        <div className='w-auto'>
            <div className="flex items-center gap-1.5 py-1">
                <div> {icon} </div>
                <div className="text-[var(--text-neutral)] text-body-micro medium">
                    {text}
                </div>
            </div>
        </div>
    );
};

export default AssignItem;
