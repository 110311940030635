'use client';

import { AnimatePresence, motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useDeleteOnePost } from 'src/api/post/useDeleteOnePost';
import ToastMessage from 'src/components/ToastMessage';
import Modal from 'src/components/ui/Modals/Modal';
import { IPost } from 'src/lib/types';

export default function DeletePost(props: {
  show: boolean;
  handleClose: Function;
  post?: IPost;
}) {
  const { show, handleClose, post } = props;
  const [deleteOnePost, { loading: deleteOnePostLoading }] = useDeleteOnePost();

  const navigate = useNavigate();
  const { workspaceSlug } = useParams();

  const handleDelete = () => {
    if (post) {
      deleteOnePost({
        variables: {
          where: { id: post.id },
        },
        onCompleted: (data) => {
          toast((t) => (
            <ToastMessage id={t.id} title="This post has been deleted." />
          ));
          handleClose();
          navigate(`/workspace/${workspaceSlug}/project`, { replace: true });
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={'There was an error deleting this post.'}
            >
              {error.message}
            </ToastMessage>
          ));
        },
      });
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center"
        >
          <Modal
            open={show}
            data={{
              subtitle: 'You’re about to delete this post',
              snippet:
                'Are you sure you want to delete this post? You cannot undo this action.',
              btn1: 'Cancel',
              btn2: 'Delete',
              btn1Type: 'neutral',
              btn2Type: 'destructive',
            }}
            onBtn1Click={() => handleClose()}
            onBtn2Click={() => handleDelete()}
            loading={deleteOnePostLoading}
          />
        </motion.div>
      )}
      {/* <Modal
        as={'form'}
        size="small"
        show={show}
        handleClose={handleClose}
      >
        <div className="space-y-8">
          <div className="space-y-4">
            <p className="text-base text-grey-900 font-medium">
              You’re about to delete this post
            </p>
            <p>
              Are you sure you want to delete this post?
              You cannot undo this action.
            </p>
          </div>

          <div className="flex justify-end">
            <div className="space-x-3">
              <Button
                onClick={() => handleClose()}
                type="button"
                size="medium"
                color="ghost"
              >
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                type="button"
                size="medium"
                color="negative"
                loading={deleteOnePostLoading}
                disabled={deleteOnePostLoading}
              >
                Delete
              </Button>
            </div>
          </div>
        </div>
      </Modal> */}
    </AnimatePresence>
  );
}
